import React, { useState } from 'react';
import { Box, Button, Typography, Divider, TextField } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

export const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission
  };

  const handleSocialLogin = (provider: string) => {
    // Handle social login
    console.log(`Login with ${provider}`);
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4, p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {isLogin ? 'Login' : 'Register'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          type="email"
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          margin="normal"
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          {isLogin ? 'Login' : 'Register'}
        </Button>
      </form>
      <Divider sx={{ my: 2 }}>OR</Divider>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={() => handleSocialLogin('Google')}
        sx={{ mb: 1 }}
      >
        Continue with Google
      </Button>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<AppleIcon />}
        onClick={() => handleSocialLogin('Apple')}
        sx={{ mb: 1 }}
      >
        Continue with Apple
      </Button>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<FacebookIcon />}
        onClick={() => handleSocialLogin('Facebook')}
        sx={{ mb: 1 }}
      >
        Continue with Facebook
      </Button>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<TwitterIcon />}
        onClick={() => handleSocialLogin('Twitter')}
        sx={{ mb: 2 }}
      >
        Continue with Twitter
      </Button>
      <Typography align="center">
        {isLogin ? "Don't have an account? " : "Already have an account? "}
        <Button onClick={() => setIsLogin(!isLogin)}>
          {isLogin ? 'Register' : 'Login'}
        </Button>
      </Typography>
    </Box>
  );
};