import AdminPanelIcon from '@mui/icons-material/AdminPanelSettings';
import BarChartIcon from '@mui/icons-material/BarChart';
import DocumentIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PeopleIcon from '@mui/icons-material/People';

import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { User, UserRole } from '../model';

export const Navigation = ({user} : {user : User}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  const navItems = [
    { icon: <HomeIcon />, label: 'Home', path: '/home', mobile: true },
    /*{ icon: <AssistantIcon />, label: 'Assistant', path: '/assistant', mobile : false },*/
    /*{ icon: <PeopleIcon/>, label: 'Contacts', path: '/contacts', mobile : false },*/
    { icon: <DocumentIcon />, label: 'Documents', path: '/documents', mobile: true },
    { icon: <BarChartIcon />, label: 'Wealth', path: '/wealth', mobile: true },
    { icon: <ReceiptLongIcon />, label: 'Taxes', path: '/taxes', mobile: true },
    { icon: <AdminPanelIcon />, label: 'Admin', path: '/admin', admin: true },
  ]
  .filter(item => !item.admin || (item.admin && user.role === UserRole.ADMIN));

  return isDesktop ? (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          borderRight: 'none',
          bgcolor: 'background.default',
        },
      }}
    >
      <List sx={{ py: 2 }}>
        {navItems.map((item, index) => (
          <ListItem
            component={Link}
            to={item.path}
            key={index}
            sx={{
              borderRadius: 2,
              mb: 1,
              mx: 1,
              color: location.pathname === item.path ? 'primary.main' : 'text.secondary',
              '&:hover': {
                bgcolor: 'rgba(91, 62, 189, 0.04)',
              },
            }}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  ) : (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        borderTop: '1px solid #EEEEF1',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.05)',
        zIndex: 1000,
        paddingBottom: 'env(safe-area-inset-bottom)'
      }}
    >
      <Grid container justifyContent="space-around">
        {navItems
          .filter(m => m.mobile)
          .map((item, index) => (
            <Grid item key={index}>
              <IconButton
                component={Link}
                to={item.path}
                sx={{
                  color: location.pathname === item.path ? 'primary.main' : 'text.secondary',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  py: 1,
                  px: 2,
                  paddingBottom: 2,
                  borderRadius: 0,
                  '&:hover': {
                    bgcolor: 'rgba(91, 62, 189, 0.04)',
                  },
                }}
              >
                {item.icon}
                <Typography variant="caption" sx={{ mt: 0.5, fontSize: '0.7rem' }}>{item.label}</Typography>
              </IconButton>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};