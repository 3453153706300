import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


interface OnboardingProps {
  setIsOnboarded: (value: boolean) => void;
}

export const Onboarding: React.FC<OnboardingProps> = ({ setIsOnboarded }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [documents, setDocuments] = useState<File[]>([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setDocuments([...documents, ...Array.from(event.target.files)]);
    }
  };

  const handleRemoveDocument = (index: number) => {
    setDocuments(documents.filter((_, i) => i !== index));
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // TODO: Implement actual data submission
    console.log('Submitted:', { name, address, documents });
    localStorage.setItem('onboardingCompleted', 'true');
    setIsOnboarded(true);
    navigate('/home');
  };

  const steps = ['Personal Information', 'Document Upload', 'Review'];

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Welcome! Let's get you set up.
      </Typography>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3 }}>
        <form onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <>
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <input
                  accept="*/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    size="large"
                  >
                    Upload Documents
                  </Button>
                </label>
              </Box>
              <List>
                {documents.map((doc, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDocument(index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={doc.name} secondary={`${(doc.size / 1024).toFixed(2)} KB`} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {activeStep === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>Review Your Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>Name:</strong> {name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>Address:</strong> {address}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography><strong>Documents:</strong> {documents.length} uploaded</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button
              onClick={handleBack}
              disabled={activeStep === 0}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
              endIcon={activeStep === steps.length - 1 ? <CheckCircleOutlineIcon /> : null}
              size="large"
            >
              {activeStep === steps.length - 1 ? 'Complete Onboarding' : 'Next'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};