export const TaxableSubTypeDescriptions: { [key: string]: string } = {
    // Income
   'EMPLOYMENT': 'Income from employment',
   'SELF_EMPLOYMENT': 'Income from self-employment',
   'INVESTMENT': 'Income from investments',
   'PROPERTY': 'Income from property rentals',
   'CAPITAL_GAINS': 'Income from capital gains',
   'PENSION': 'Income from pensions',
   'ALIMONY': 'Income from alimony',
   'GAMBLING': 'Income from gambling winnings',
   'OTHER': 'Other types of income',
 
   'INVESTMENT_CATEGORY_A': 'Income from investment category A',
   'INVESTMENT_CATEGORY_B': 'Income from investment category B',
   'WITHOLDING_TAX': 'Income from withholding tax',
 
   // Assets
   'VALUE': 'Taxable wealth value',
   'PORTFOLIO': 'Portfolio',
   'CASH': 'Cash',
   'SECURITY': 'Securities',
   'PRIVATE_EQUITY': 'Private Equity',
   'REAL_ESTATE': 'Real Estate',
   'LIFE_INSURANCE': 'Life Insurance',
   'CRYPTO': 'Crypto',
 
   // Deductible
   'WORK_RELATED': 'Deductible work-related expenses',
   'PENSION_CONTRIBUTION': 'Deductible pension contributions',
   'INSURANCE_PREMIUMS': 'Deductible insurance premiums',
   'LOAN_INTEREST': 'Deductible loan interest',
   'SOCIAL_SECURITY': 'Deductible social security contributions',
   'DEPENDENTS': 'Deductible for dependents',
   'HEALTHCARE_INSURANCE_PREMIUM': 'Deductible healthcare insurance premiums',
   'HEALTHCARE_NOT_COVERED_COST': 'Deductible healthcare costs not covered by insurance',
   'BANK_EXPENSES': 'Deductible bank expenses',
   'CHARITABLE_DONATIONS': 'Deductible charitable donations',
   'POLITICAL_DONATIONS' : 'Deductible political donations',
   'GIFT': 'Gifts',
   'PROPERTY_MAINTENANCE': 'Deductible property maintenance costs',
   'EDUCATION_TRAINING': 'Deductible education and training expenses',
   'CHILDCARE_COST' : 'Childcare cost',
   'FAMILY': 'Deductible family-related expenses'
 };