import FlagIcon from '@mui/icons-material/Flag';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    Autocomplete,
    Box,
    Dialog,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { adminGetDocuments, adminGetUsers } from '../../data';
import { User, UserDocument } from "../../model";
import AdminDocumentView from './AdminDocumentView';

const AdminDocumentTable = ({ user }: { user: User }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [documents, setDocuments] = useState<UserDocument[]>([])
    const [documentsOrderBy, setDocumentsOrderBy] = useState<keyof UserDocument>('title');
    const [documentsOrder, setDocumentsOrder] = useState<'asc' | 'desc'>('asc');
    const [selectedDocument, setSelectedDocument] = useState<UserDocument | null>(null);
    const [filterValidated, setFilterValidated] = useState<'ALL' | 'FLAGGED' | 'VALIDATED' | 'NOT_VALIDATED' | 'NOT_RELEVANT'>('NOT_VALIDATED');
    const [searchTitle, setSearchTitle] = useState<string>('');
    const [filterUser, setFilterUser] = useState<User | null>(null);

    const nodeRef = useRef(null);

    useEffect(() => {
        const loadDocuments = async () => {
            try {
                const fetchedDocuments = (await adminGetDocuments())
                    .filter(doc => doc.state === "PROCESSED")

                setDocuments(fetchedDocuments);
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
        }
        const loadUsers = async () => {
            try {
                const fetchedUsers = await adminGetUsers();
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        loadUsers();
        loadDocuments()
    }, []);

    const handleDocumentsRequestSort = (property: keyof UserDocument) => {
        const isAsc = documentsOrderBy === property && documentsOrder === 'asc';
        setDocumentsOrder(isAsc ? 'desc' : 'asc');
        setDocumentsOrderBy(property);
    };

    const handleOnValidated = (document: UserDocument) => {
        setSelectedDocument(undefined)
        setDocuments(documents.map(doc =>
            doc.id === document.id ? { ...document, validated: true } : doc
        ));
    }

    const handleOnDeleted = (document: UserDocument) => {
        setSelectedDocument(undefined)
        setDocuments(documents.filter(doc => doc.id !== document.id));
    }

    const hasFlag = (document: UserDocument): boolean => {
        return document.activities.find(a => a.flaggedFields?.length > 0) !== undefined || document.positions.find(a => a.flaggedFields?.length > 0) !== undefined
    }

    const sortedDocuments = documents.sort((a, b) => {
        if (a[documentsOrderBy] < b[documentsOrderBy]) return documentsOrder === 'asc' ? -1 : 1;
        if (a[documentsOrderBy] > b[documentsOrderBy]) return documentsOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const filteredDocuments = sortedDocuments.filter(doc => 
        (searchTitle === ''
            || doc.title?.toLowerCase().includes(searchTitle.toLowerCase())
            || doc.filename?.toLowerCase().includes(searchTitle.toLowerCase()))
        && (filterValidated === 'ALL'
            || (filterValidated === 'FLAGGED' && hasFlag(doc))
            || (filterValidated === 'VALIDATED' && doc.validated)
            || (filterValidated === 'NOT_VALIDATED' && !doc.validated)
            || (filterValidated === 'NOT_RELEVANT' && doc.notTaxRelevant))
        && (!filterUser || doc.userId === filterUser.id)
    );

    const handleDocumentClick = (doc: UserDocument) => {
        setSelectedDocument(doc);
    };

    const handleCloseDialog = () => {
        setSelectedDocument(null);
    };

    const fadeStyles = `
        .fade-exit {
            opacity: 1;
        }
        .fade-exit-active {
            opacity: 0;
            transition: opacity 500ms ease-in;
        }
    `;

    return (
        <>
            <style>{fadeStyles}</style>
            <Box sx={{ mb: 2, display: 'flex', gap: 2, p: 2, flexWrap: 'wrap' }}>
                <TextField
                    select
                    label="Filter"
                    value={filterValidated}
                    onChange={(e) => setFilterValidated(e.target.value as 'ALL' | 'FLAGGED' | 'VALIDATED' | 'NOT_RELEVANT' | 'NOT_VALIDATED')}
                    sx={{ minWidth: 200 }}
                >
                    <MenuItem value="ALL">All</MenuItem>
                    <MenuItem value="FLAGGED">Flagged</MenuItem>
                    <MenuItem value="VALIDATED">Validated</MenuItem>
                    <MenuItem value="NOT_VALIDATED">Not Validated</MenuItem>
                    <MenuItem value="NOT_RELEVANT">Not Tax Relevant</MenuItem>
                </TextField>
                <Autocomplete
                    options={users}
                    getOptionLabel={(option) => `(${option.id}) - ${option.name}`}
                    renderInput={(params) => <TextField {...params} label="Filter by user" />}
                    value={filterUser}
                    onChange={(event, newValue) => {
                        setFilterUser(newValue);
                    }}
                    sx={{ minWidth: 200 }}
                />
                <TextField
                    label="Search by title or filename"
                    value={searchTitle}
                    onChange={(e) => setSearchTitle(e.target.value)}
                    sx={{ minWidth: 300 }}
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {['id', 'userId', 'taxYear', 'title'].map((column) => (
                                <TableCell
                                    key={column}
                                    sortDirection={documentsOrderBy === column ? documentsOrder : false}
                                    onClick={() => handleDocumentsRequestSort(column as keyof UserDocument)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                    {documentsOrderBy === column ? (documentsOrder === 'desc' ? ' ▼' : ' ▲') : ''}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TransitionGroup component={TableBody}>
                        {filteredDocuments.map((doc) => (
                            <CSSTransition
                                key={doc.id}
                                timeout={500}
                                classNames="fade"
                                nodeRef={nodeRef}
                            >
                                <TableRow
                                    ref={nodeRef}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    onClick={() => handleDocumentClick(doc)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{doc.id}</TableCell>
                                    <TableCell>{doc.userId}</TableCell>
                                    <TableCell>
                                        {doc.taxYear}
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            {doc.validated && <Tooltip title="Document has been verified / validated"><VerifiedIcon sx={{ mr: 1 }} /></Tooltip>}
                                            {doc.notTaxRelevant && (
                                                <Tooltip title="Not tax relevant">
                                                    <WarningAmberIcon sx={{ mr: 1 }} />
                                                </Tooltip>
                                            )}
                                            {hasFlag(doc) && (
                                                <Tooltip title="Flagged">
                                                    <FlagIcon sx={{ mr: 1 }} />
                                                </Tooltip>
                                            )}
                                            <Box>
                                                {doc.title}
                                                <Typography variant="caption" color="text.secondary" display="block">
                                                    {doc.filename}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </Table>
            </TableContainer>
            {selectedDocument && <Dialog
                open={selectedDocument !== null}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <AdminDocumentView user={user} id={selectedDocument.id} onValidated={handleOnValidated} onDeleted={handleOnDeleted} validatedDocuments={documents.filter(doc => doc.validated)} />
            </Dialog>}
        </>
    )
}

export default AdminDocumentTable