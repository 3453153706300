import {
    Box,
    Button,
    ButtonGroup,
    Fade,
    Grid,
    MenuItem,
    SelectChangeEvent,
    Slider,
    TextField,
    Typography
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { contactName } from '../format';
import { Company, isSameContact, MartialStatus, TaxProfile } from '../model';

interface ProfileEditProps {
    profile: TaxProfile;
    employers: Company[];
    onSave: (profile: TaxProfile) => void;
    onCancel: () => void;
}

const StructureInformation: {
    name: string,
    properties?: (keyof TaxProfile)[],
    children?: {
        name: string,
        properties: (keyof TaxProfile)[]
    }[]
}[] = [
        {
            name: "Personal Information",
            children: [
                {
                    name: "Basic",
                    properties: ["maritalStatus", "numberOfChildren", "childrenAges"]
                },
                {
                    name: "Family Support",
                    properties: ["pensionBenefitToPartner", "alimonyToPartner", "alimonyToChildren"]
                },
                {
                    name: "Employers",
                    properties: ["mainEmployer", "secondaryEmployer", "selfEmployer"]
                },
                {
                    name: "Main Employment",
                    properties: ["bikeToWork", "carToWork", "subsidizedMeals", "shiftWork", "officePensum", "abonementCharges", "effectiveOtherExpenses", "trainingAndEducationExpenses", "motorisedKmPerYearToWork", "motorisedToWorkType"]
                },
                {
                    name: "Secondary Employment",
                    properties: ["secondaryEmploymentExpenses"]
                }
            ]
        }
    ];

const PropertyInformation: Record<keyof TaxProfile, { title: string; type: string; values?: string[]; note?: string; hide?: boolean }> = {
    contact: { title: "Contact", type: "Contact", hide: true }, // do not allow edition for now
    maritalStatus: { title: "Marital Status", type: "select", values: Object.values(MartialStatus) },
    numberOfChildren: { title: "Number of Children", type: "number" },
    childrenAges: { title: "Ages of Children", type: "string", hide : true }, // do not show this at the moment

    mainEmployer: { title: "Main Employer", type: "employer" },
    secondaryEmployer: { title: "Secondary Employer", type: "employer" },
    selfEmployer: { title: "Self Employer", type: "employer" },

    officePensum: { title: "Office Work Percentage", type: "percentage", note: "Required to calculate work-related deductions" },
    abonementCharges: { title: "Public Transport Charges", type: "number", note: "Can be deducted from taxable income" },
    bikeToWork: { title: "Bike to Work", type: "boolean", note: "May qualify for special deductions in some cantons" },
    carToWork: { title: "Car to Work", type: "boolean", note: "May qualify for special deductions in some cantons" },
    motorisedKmPerYearToWork: { title: "Motorized km to Work per Year", type: "number", note: "Used to calculate vehicle expense deductions" },
    motorisedToWorkType: { title: "Type of Motorized Transport", type: "select", values: ["car", "motorbike"], note: "Different vehicles have different deduction rates" },
    subsidizedMeals: { title: "Subsidized Meals", type: "boolean", note: "Affects meal expense deductions" },
    shiftWork: { title: "Shift Work", type: "boolean", note: "May qualify for additional deductions" },
    effectiveOtherExpenses: { title: "Other Work-related Expenses", type: "number", note: "Can be deducted if they exceed the standard deduction" },
    trainingAndEducationExpenses: { title: "Training and Education Expenses", type: "number", note: "May be deductible if related to current profession" },
    secondaryEmploymentExpenses: { title: "Secondary Employment Expenses", type: "number", note: "Can be deducted from secondary employment income" },
    alimonyToPartner: { title: "Alimony to Partner", type: "number", note: "Can be deducted from taxable income" },
    alimonyToChildren: { title: "Alimony to Children", type: "number", note: "Can be deducted from taxable income" },
    pensionBenefitToPartner: { title: "Pension Benefit to Partner", type: "number", note: "May be deductible in certain circumstances" }
};

export const ProfileEdit: React.FC<ProfileEditProps> = ({ profile, employers, onSave }) => {
    const [data, setData] = useState<TaxProfile>(profile);

    const handleSave = () => {
        // select from data all variables that are actually editable
        const newProfile: TaxProfile = { contact: data.contact }
        for (const prop in PropertyInformation) {
            newProfile[prop] = data[prop];
        }
        onSave(newProfile)
    }

    const handleChange = (name: keyof TaxProfile, value: string | boolean) => {
        let typedValue: any = value;

        switch (PropertyInformation[name].type) {
            case 'percentage':
                typedValue = typeof value === 'string' ? Number(value) : value;
                break;
            case 'number':
                typedValue = typeof value === 'string' ? Number(value) : value;
                break;
            case 'boolean':
                typedValue = typeof value === 'string' ? value === 'true' : value;
                break;
            default:
                break;
        }

        setData({ ...data, [name]: typedValue });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        const { name, value } = e.target;

        handleChange(name as keyof TaxProfile, value);
    };

    const isFieldRelevant = (prop: keyof TaxProfile) => {
        const mainEmployeer = data.mainEmployer
        const secondaryEmployeer = data.secondaryEmployer
        const numberOfChildren = data.numberOfChildren
        const isSeparated = data.maritalStatus === MartialStatus.SEPARATED
        const carToWork = data.carToWork

        switch (prop) {
            case 'childrenAges':
                return numberOfChildren && numberOfChildren > 0;
            case 'officePensum':
                return !!mainEmployeer;
            case 'motorisedKmPerYearToWork':
            case 'motorisedToWorkType':
                return carToWork
            //case 'daysAtWork':
            case 'abonementCharges':
            case 'bikeToWork':
            case 'carToWork':
            case 'subsidizedMeals':
            case 'shiftWork':
            case 'effectiveOtherExpenses':
            case 'trainingAndEducationExpenses':
                return !!mainEmployeer;
            case 'alimonyToPartner':
            case 'alimonyToChildren':
                return isSeparated;
            case 'secondaryEmploymentExpenses':
                return !!secondaryEmployeer
            case 'pensionBenefitToPartner':
                return isSeparated;
            default:
                return true;
        }
    };

    const filteredStructureInformation = useMemo(() => {
        return StructureInformation.map(section => ({
            ...section,
            children: section.children?.map(child => ({
                ...child,
                properties: child.properties
            }))
        }))
            .filter(section => section.children?.some(child => child.properties.length > 0) || section.properties?.length > 0);
    }, []);

    const renderInput = (prop: keyof TaxProfile) => {
        if (!isFieldRelevant(prop)) return null;

        const value: any = data[prop] !== undefined ? data[prop] : data[prop];
        const { type, title: label, values } = PropertyInformation[prop];

        const inputContent = () => {
            switch (type) {
                case 'boolean':
                    const is = (expected: boolean): boolean => {
                        if ((prop in data && data[prop] === expected) || (value !== undefined && value === expected)) {
                            return true
                        }
                        return false
                    }
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 2 }}>
                            <Typography sx={{ mr: 2 }}>{label}</Typography>
                            <ButtonGroup>
                                <Button
                                    variant={(is(true) ? "contained" : "outlined")}
                                    onClick={() => handleChange(prop, true)}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={(is(false) ? "contained" : "outlined")}
                                    onClick={() => handleChange(prop, false)}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Box>
                    );
                case 'number':
                    return (
                        <TextField
                            fullWidth
                            label={label}
                            name={prop}
                            value={value ?? ''}
                            onChange={handleInputChange}
                            type="number"
                            margin="normal"
                        />
                    );
                case 'percentage':
                    return (
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Typography gutterBottom>{label}</Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <Slider
                                        name={prop}
                                        value={value ?? 0}
                                        onChange={(_, newValue) => handleChange(prop, newValue + '')}
                                        min={0}
                                        max={1}
                                        step={0.01}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {((value ?? 0) * 100).toFixed(0)}%
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    );
                case 'string':
                    return (
                        <TextField
                            fullWidth
                            label={label}
                            name={prop}
                            value={value ?? ''}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                    );
                case 'select':
                    return (
                        <TextField
                            select
                            fullWidth
                            label={label}
                            name={prop}
                            value={value || ''}
                            onChange={handleInputChange}
                            margin="normal"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {values?.map(option => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </TextField>
                    );
                case 'employer':
                    const list: Company[] = []
                    for (const employer of employers) {
                        if (!!value && isSameContact(employer, value)) {
                            // Hacky workaround otherwise the reference is another and we cannot compare them
                            list.push(value)
                        } else {
                            list.push(employer)
                        }
                    }

                    return (
                        <TextField
                            select
                            fullWidth
                            label={label}
                            name={prop}
                            value={value || ''}
                            onChange={handleInputChange}
                            margin="normal"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {list?.map((option: any) => <MenuItem key={contactName(option)} value={option}>
                                {contactName(option)}
                            </MenuItem>
                            )}
                        </TextField>
                    );
                default:
                    return null;
            }
        };

        return (
            <Fade in={isFieldRelevant(prop)} timeout={500}>
                <Box>
                    {inputContent()}
                    {PropertyInformation[prop].note && (
                        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                            {PropertyInformation[prop].note}
                        </Typography>
                    )}
                </Box>
            </Fade>
        );
    };

    const renderSection = (section: typeof StructureInformation[0], level: number = 0) => {
        const visibleProperties = section.properties?.filter(
            (prop) => !PropertyInformation[prop].hide && isFieldRelevant(prop) && (PropertyInformation[prop].type !== 'sum-of-values')
        ) || [];

        const visibleChildren = section.children?.filter(child => {
            const childVisibleProperties = child.properties.filter(
                (prop) => !PropertyInformation[prop].hide && isFieldRelevant(prop) && (PropertyInformation[prop].type !== 'sum-of-values')
            );
            return childVisibleProperties.length > 0;
        });

        if (visibleProperties.length === 0 && (!visibleChildren || visibleChildren.length === 0)) {
            return null;
        }

        return (
            <Box key={section.name} sx={{ mb: 4, p: 0 }}>
                {(visibleProperties.length > 0 || (visibleChildren && visibleChildren.length > 0)) && (
                    level === 0 ? (
                        <Typography variant="h5" sx={{ pb: 2 }} gutterBottom>
                            {section.name}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="subtitle1" gutterBottom>
                                {section.name}
                            </Typography>
                            <Box sx={{ width: '100%', height: '1px', bgcolor: 'divider', my: 1 }} />
                        </>
                    )
                )}
                <Grid container spacing={2}>
                    {visibleProperties.map((prop) => (
                        <Grid item xs={12} sm={6} md={4} key={prop}>
                            {renderInput(prop)}
                        </Grid>
                    ))}
                </Grid>
                {visibleChildren?.map(child => renderSection(child, level + 1))}
            </Box>
        );
    };

    return (
        <Box sx={{ mt: 2, minHeight: '100vh' }}>
            {filteredStructureInformation.map(section => renderSection(section))}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave()}
                >
                    Save Changes
                </Button>
            </Box>
        </Box>
    );
};