import { Add, Upload } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  createTheme,
  Fab,
  ThemeProvider,
  useMediaQuery,
  Zoom
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { fetchUser, uploadDocuments } from './data';
import { User } from './model';
import Admin from './pages/Admin/Admin';
import { Assistant } from './pages/Assistant';
import { Auth } from './pages/Auth';
import { Contacts } from './pages/Contacts';
import { Documents } from './pages/Documents';
import { Home } from './pages/Home';
import { Intro } from './pages/Intro';
import { Onboarding } from './pages/Onboarding';
import { Taxes } from './pages/Taxes/Taxes';
import { Wealth } from './pages/Wealth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5508f4',
    },
    secondary: {
      main: '#FF8C00', // Changed to a warm orange that complements the primary red
    },
    warning: {
      main: '#FFA500', // Add warning color (e.g., orange)
    },
    error: {
      main: '#FF0000', // Add error color (e.g., red)
    },
    background: {
      default: '#f8fafc',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1e293b',
      secondary: '#64748b',
    }
  },
  typography: {
    fontFamily: 'SF Pro Display, Arial, sans-serif',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          border: '1px solid #E5E7EB',
          borderRadius: 4,
          padding: 16
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
          fontWeight: 600,
          boxShadow: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          borderRadius: 4,
          '& .MuiOutlinedInput-root': {
            borderRadius: 4,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
});

const AppContent: React.FC = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnboarded, setIsOnboarded] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user: User = await fetchUser()
        setUser(user)

        // Redirect to "/home" if user exists and current page is "/"
        if (user?.id && location.pathname === '/') {
          navigate('/home');
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [location.pathname, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user: User = await fetchUser()
        setUser(user)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  /*
useEffect(() => {
  const checkOnboardingStatus = async () => {
    // TODO: Implement actual check for onboarding status
    const onboardingCompleted = localStorage.getItem('onboardingCompleted') === 'true';
    setIsOnboarded(onboardingCompleted);
  };

  checkOnboardingStatus();
}, []);*/

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setIsUploading(true);
      setUploadProgress(0);
      try {
        await uploadDocuments(Array.from(files));
        setUploadProgress(100)
        // Success notification
        // You can add a Snackbar or Toast notification here
      } catch (error) {
        console.error('Error uploading documents:', error);
        // Error notification
        // You can add a Snackbar or Toast notification here
      } finally {
        setIsUploading(false);
        setUploadProgress(0);
      }
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isOnboarded && location.pathname !== '/onboarding') {
    return <Navigate to="/onboarding" replace />;
  }

  return (
    <Box sx={{ display: 'flex', bgcolor: 'background.default', minHeight: '100vh' }}>
      {isDesktop && location.pathname !== '/' && location.pathname !== '/auth' && !location.pathname.includes('/page/') && <Navigation user={user} />}
      <Box sx={{
        flexGrow: 1,
        pb: { xs: 7, md: 0 }, // Add padding bottom on mobile
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box sx={{
          maxWidth: '1200px',
          width: '100%',
        }}>
          <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/auth" element={<Auth />} />
            {user && <Route path="/home" element={<Home user={user} />} />}
            <Route path="/assistant" element={<Assistant user={user || {} as User} />} />
            {user && <Route path="/contacts" element={<Contacts user={user} />} />}
            {user && <Route path="/wealth" element={<Wealth user={user} />} />}
            {user && <Route path="/taxes" element={<Taxes user={user} />} />}
            {user && <Route path="/admin" element={<Admin user={user} />} />}
            {user && <Route path="/admin/taxes/:otherUserId" element={<Taxes user={user} />} />}
            <Route path="/onboarding" element={<Onboarding setIsOnboarded={setIsOnboarded} />} />
            <Route path="/documents" element={<Documents user={user} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
      </Box>
      {!isDesktop && location.pathname !== '/' && location.pathname !== '/auth' && !location.pathname.includes('/page/') && <Navigation user={user} />}
      {/*<Snackbar
        open={(showInstallPrompt || (isIOS && !isInStandaloneMode)) && showInstallBanner}
        message={isIOS ? "Add this app to your home screen" : "Install this app on your home screen"}
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={isIOS ? handleShowInstallInstructions : handleInstallClick}
            >
              {isIOS ? "How to install" : "Install"}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseBanner}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Dialog open={showInstallInstructions} onClose={() => setShowInstallInstructions(false)}>
        <DialogTitle>How to Install on iOS</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            1. Tap the Share icon in Safari's toolbar.<br />
            2. Scroll down and tap "Add to Home Screen".<br />
            3. Enter a name for the app and tap "Add".
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstallInstructions(false)}>Close</Button>
        </DialogActions>
      </Dialog>
        */}
      {user?.id && (
        <>
          <input
            accept="*/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="raised-button-file">
            <Zoom in={!isUploading}>
              <Fab
                color="primary"
                aria-label="upload"
                component="span"
                sx={{
                  position: 'fixed',
                  bottom: { xs: 80, md: 20 },
                  right: { xs: 16, md: 20 },
                }}
              >
                <Add />
              </Fab>
            </Zoom>
          </label>
          {isUploading && (
            <Fab
              color="primary"
              aria-label="uploading"
              sx={{
                position: 'fixed',
                bottom: { xs: 80, md: 20 },
                right: { xs: 16, md: 20 },
              }}
              disabled
            >
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size={24}
                thickness={4}
                sx={{
                  color: 'white',
                  position: 'absolute',
                }}
              />
              <Upload />
            </Fab>
          )}
        </>
      )}
    </Box>
  );
};

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};