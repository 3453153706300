import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, Chip, Dialog, DialogContent, Divider, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchDocuments } from '../data';
import { useInterval } from '../hooks/useInterval';
import { User, UserDocument } from '../model';
import DocumentView from './DocumentView';

export const Documents = ({ user }: { user: User }) => {
  const [documents, setDocuments] = useState<UserDocument[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchAndUpdateDocuments = () => {
    fetchDocuments().then((newDocuments: UserDocument[]) => {
      setDocuments(newDocuments);
    }).catch((error) => {
      console.error("Error fetching documents:", error);
    });
  };

  useEffect(() => {
    fetchAndUpdateDocuments();
  }, []);

  useInterval(fetchAndUpdateDocuments, 2000);

  const handleDocumentClick = (docId: number) => {
    setSelectedDocumentId(docId);
  };

  const handleCloseDialog = () => {
    setSelectedDocumentId(null);
  };

  const handleOnDeleted = (document : UserDocument) => {
    setSelectedDocumentId(null)
    setDocuments(documents.filter(doc => doc.id !== document.id))
  }

  const filteredDocuments = documents.filter(doc =>
    doc.title?.toLowerCase().includes(searchTerm.toLowerCase()) || doc.filename.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedDocuments = filteredDocuments
    .filter(doc => doc.state === "PROCESSED")
    .reduce((acc, doc) => {
      if (!acc[doc.taxYear]) {
        acc[doc.taxYear] = [];
      }
      acc[doc.taxYear].push(doc);
      return acc;
    }, {} as Record<number, UserDocument[]>);

  const processingDocuments = documents.filter(doc => doc.state !== "PROCESSED");
  const processingCount = processingDocuments.length;
  const totalCount = documents.length;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>Documents</Typography>

      <TextField
        fullWidth
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {processingCount > 0 && (
        <Card elevation={3} sx={{ mb: 4, p: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }} align='center'>
            {processingCount} document(s) being processed
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(1 - processingCount / totalCount) * 100}
            sx={{ height: 8, borderRadius: 4 }}
          />
        </Card>
      )}
      {Object
        .entries(groupedDocuments)
        .sort(([a], [b]) => Number(b) - Number(a)).map(([year, docs]) =>
          <React.Fragment key={year}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                {Number(year) === 0 ? "Tax Year Unknown" : `Tax Year ${year}`}
              </Typography>
              <Chip
                label={`${docs.length} document${docs.length !== 1 ? 's' : ''}`}
                size="small"
                sx={{ ml: 2 }}
              />
            </Box>
            <Card key={year} elevation={3} sx={{ mb: 4, p: 0 }}>
              <List>
                {docs
                  .filter(doc => doc.state === "PROCESSED")
                  .map((doc, index) => (
                    <React.Fragment key={doc.id}>
                      <ListItem
                        onClick={() => handleDocumentClick(doc.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <ListItemText
                          primary={doc.title}
                          secondary={
                            <>
                              <Typography variant="caption" color="text.secondary">
                                {doc.filename}
                              </Typography>
                              <br />
                              {doc.date && (
                                <Typography variant="caption" color="text.secondary">
                                  {new Date(doc.date).toLocaleDateString()}
                                </Typography>
                              )}
                            </>
                          }
                          primaryTypographyProps={{ fontWeight: 'medium' }}
                        />
                      </ListItem>
                      {index < docs.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
              </List>
            </Card>
          </React.Fragment>
        )}
      {selectedDocumentId && <Dialog
        open={selectedDocumentId !== null}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        fullScreen={window.innerWidth <= 600}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            zIndex: theme.zIndex.modal + 1, // Ensure the button is above the dialog content
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {selectedDocumentId && <DocumentView user={user} id={selectedDocumentId} onDeleted={handleOnDeleted} />}
        </DialogContent>
      </Dialog>}
    </Box>
  );
};