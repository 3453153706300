import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { MenuItem, Tab, Tabs } from '@mui/material';

import {
  Avatar,
  Box,
  Card,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchPositions } from '../data';
import { contactName, formatCurrency } from '../format';
import { Position, PositionType, User } from '../model';
import { hexToRgb } from '../util';
import { TaxableSubTypeDescriptions } from './descriptions';

export const Wealth = ({ user }: { user: User }) => {
  const [positions, setPositions] = useState<Position[]>([]);
  const [expandedPositions, setExpandedPositions] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [tab, setTab] = useState<'assets' | 'liabilities'>('assets');
  const [selectedYear, setSelectedYear] = useState(2022);
  const years = [2020, 2021, 2022, 2023, 2024]; // Add more years as needed

  useEffect(() => {
    fetchPositions(selectedYear).then(pos => setPositions(pos))
  }, [selectedYear]);

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(Number(event.target.value));
  };


  const groupPositionsByTypeAndSum = useCallback(() => {
    const groupedPositions = positions
      .reduce((acc, a) => {
        acc[a.type] = (acc[a.type] || 0) + a.value;
        return acc;
      }, {} as { [key in PositionType]?: number }) || [];

    return Object.entries(groupedPositions)
      .map(([type, totalAmountNet]) => ({
        type: type as PositionType,
        value: totalAmountNet
      }))
      .sort((a: any, b: any) => b.value - a.value);
  }, [positions]);

  const getIconForType = useCallback((type: PositionType) => {
    switch (type) {
      case PositionType.CASH:
        return <AccountBalanceIcon />;
      case PositionType.PORTFOLIO:
      case PositionType.SECURITY:
      case PositionType.CRYPTO:
      case PositionType.PRIVATE_EQUITY:
        return <ShowChartIcon />;
      default:
        return <AccountBalanceIcon />;
    }
  }, []);

  const getColorForType = useCallback((type: PositionType) => {
    switch (type) {
      case PositionType.CASH:
        return '#009f72';
      case PositionType.PORTFOLIO:
        return '#5508F4';
      case PositionType.PRIVATE_EQUITY:
        return '#E11D48';
      case PositionType.CRYPTO:
        return '#43A047';
      case PositionType.SECURITY:
        return '#2E7D32';
      default:
        return '#C8E6C9'; // Very light green for any other cases
    }
  }, []);

  const positionData = useMemo(() => groupPositionsByTypeAndSum()
    .map(item => ({
      type: item.type,
      value: item.value,
      icon: getIconForType(item.type),
      color: getColorForType(item.type),
    })), [groupPositionsByTypeAndSum, getIconForType, getColorForType]);

  const filteredPositionData = useMemo(() => {
    return positionData.map(item => ({
      ...item,
      value: positions
        .filter(p => p.type === item.type && (tab === 'assets' ? p.value > 0 : p.value < 0))
        .reduce((sum, p) => sum + Math.abs(p.value), 0),
    }))
    .filter(item => item.value >= 0);
  }, [positionData, positions, tab]);

  const totalPositions = useMemo(() => filteredPositionData.reduce((sum, p) => sum + p.value, 0), [filteredPositionData]);

  useEffect(() => {
    if (searchTerm) {
      const matchingGroups = new Set<string>();
      const matchingPositions = new Set<string>();

      positions
        .forEach(a => {
          const searchTermLower = searchTerm.toLowerCase();
          if (
            searchTermLower.length === 0 ||
            contactName(a.issuer)?.toLowerCase().includes(searchTermLower) ||
            (a.name && a.name.toLowerCase().includes(searchTermLower)) ||
            (a.type === PositionType.SECURITY && a.isin && a.isin.toLowerCase().includes(searchTermLower)) ||
            (a.type === PositionType.CASH && a.number && a.number.toLowerCase().includes(searchTermLower)) ||
            (a.type === PositionType.CASH && a.iban && a.iban.toLowerCase().includes(searchTermLower))
          ) {
            matchingGroups.add(contactName(a.issuer) || '');
            matchingPositions.add(a.type);
          }
        });

      setExpandedPositions(matchingPositions);
    } else {
      setExpandedPositions(new Set());
    }
  }, [searchTerm, positions]);

  const renderPositionBar = () => (
    <Card sx={{ mb: 3, bgcolor: 'background.paper', overflow: 'hidden', p: 2 }}>
      <Box sx={{ display: 'flex', mb: 1, justifyContent: 'space-between' }}>
        <Box sx={{ fontWeight: 'bold' }}>Total</Box>
        <Box sx={{}}>
          {formatCurrency(totalPositions, 'CHF')}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', height: 10 }}>
        {filteredPositionData.map((pos, index) => (
          <Box
            key={index}
            sx={{
              height: '100%',
              bgcolor: pos.color,
              width: `${(pos.value / totalPositions) * 100}%`,
              transition: 'width 0.3s ease-in-out',
              borderRadius: 2,
              ml: index === 0 ? 0 : 1,
            }}
          />
        ))}
      </Box>
    </Card>
  );

  const renderPositionCard = (user: User, position: typeof positionData[0], index: number) => {
    const subPositions = positions.filter(a => a.type === position.type) || [];
    const filteredPositions = filterPositionsBySearchTerm(subPositions, searchTerm, tab);

    if (filteredPositions.length === 0) return null;

    return (
      <Card key={index} sx={{ mb: 3, bgcolor: 'background.paper', overflow: 'hidden', p: 0 }}>
        <PositionCardHeader position={position} totalPositions={totalPositions} expandedPositions={expandedPositions} setExpandedPositions={setExpandedPositions} />
        <Collapse in={expandedPositions.has(position.type) || searchTerm !== ''}>
          <Divider />
          <PositionCardContent position={position} totalPositions={totalPositions} filteredPositions={filteredPositions} tab={tab} />
        </Collapse>
      </Card>
    );
  };

  return (
    <Box sx={{ p: 2, bgcolor: 'background.default' }}>
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>Wealth</Typography>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Tabs
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Assets" value="assets" />
        <Tab label="Liabilities" value="liabilities" />
      </Tabs>
        <TextField
          select
          value={selectedYear}
          onChange={handleYearChange}
          variant="outlined"
          size="small"
          sx={{
            minWidth: 120,
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'primary.main' },
              '&:hover fieldset': { borderColor: 'primary.main' },
              '&.Mui-focused fieldset': { borderColor: 'primary.main' },
            },
            '& .MuiSelect-select': {
              paddingY: 1,
              color: 'primary.main',
              fontWeight: 'bold',
            },
          }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {renderPositionBar()}
      {filteredPositionData.map((a, index) => renderPositionCard(user, a, index))}
    </Box>
  );
};

// New components (to be defined outside of the main Wealth component):
const SearchField = ({ searchTerm, setSearchTerm }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
      sx={{
        mb: 3,
        bgcolor: 'background.paper',
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: '#E5E7EB' },
          '&:hover fieldset': { borderColor: '#E5E7EB' },
          '&.Mui-focused fieldset': { borderColor: 'primary.main' },
        },
      }}
    />
  );
};

const PositionCardHeader = ({ position, totalPositions, expandedPositions, setExpandedPositions }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'action.hover' },
      }}
      onClick={() => {
        const newExpandedAssets = new Set(expandedPositions);
        if (newExpandedAssets.has(position.type)) {
          newExpandedAssets.delete(position.type);
        } else {
          newExpandedAssets.add(position.type);
        }
        setExpandedPositions(newExpandedAssets);
      }}
    >
      <Avatar sx={{ bgcolor: hexToRgb(position.color, 0.1), color: position.color, mr: 2, borderRadius: '100%' }}>{position.icon}</Avatar>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" fontWeight="bold">{TaxableSubTypeDescriptions[position.type]}</Typography>
        <Typography variant="body2" color="text.secondary">
          {((position.value / totalPositions) * 100).toFixed(1)}%
        </Typography>
      </Box>
      <Typography variant="subtitle1" fontWeight="medium" sx={{ mr: 2 }}>
        {formatCurrency(position.value, 'CHF')}
      </Typography>
      <IconButton size="small">
        {expandedPositions.has(position.type) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box>
  );
};

const PositionCardContent = ({ position, totalPositions, filteredPositions, tab }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{
        height: 6,
        bgcolor: 'action.hover',
        borderRadius: 3,
        overflow: 'hidden',
        mb: 2
      }}>
        <Box sx={{
          width: `${(position.value / totalPositions) * 100}%`,
          height: '100%',
          bgcolor: position.color,
          transition: 'width 0.3s ease-in-out',
        }} />
      </Box>
      <List dense disablePadding>
        {filteredPositions
          .filter(subPosition => tab === 'assets' ? subPosition.value >= 0 : subPosition.value < 0)
          .map((subPosition, index) => (
            <ListItem
              key={index}
              sx={{
                py: 1.5,
                px: 2,
                borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                '&:hover': { bgcolor: 'action.hover' },
                '&:last-child': { borderBottom: 'none' },
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: 'text.primary' }}>
                    {contactName(subPosition.issuer) || TaxableSubTypeDescriptions[subPosition.type]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: 'text.primary' }}>
                    {(subPosition.iban || subPosition.isin || subPosition.number || subPosition.name)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" align="right" fontWeight="medium" sx={{ color: 'text.primary' }}>
                    {formatCurrency(subPosition.value, 'CHF')}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

const filterPositionsBySearchTerm = (positions: Position[], searchTerm: string, tab: 'assets' | 'liabilities') => {
  const searchTermLower = searchTerm.toLowerCase();

  return positions.filter(a =>
    (tab === 'assets' ? a.value >= 0 : a.value < 0) &&
    (
      (searchTermLower.length === 0) ||
      (a.name?.toLowerCase().includes(searchTermLower)) ||
      (contactName(a.issuer)?.toLowerCase().includes(searchTermLower)) ||
      (a.type === PositionType.SECURITY && a.isin && a.isin.toLowerCase().includes(searchTermLower)) ||
      (a.type === PositionType.CASH && a.number && a.number.toLowerCase().includes(searchTermLower)) ||
      (a.type === PositionType.CASH && a.iban && a.iban.toLowerCase().includes(searchTermLower)))
  );
};