import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
    Box,
    Button,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { adminGetUsers, uploadDocuments } from '../../data';
import { User } from "../../model";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminUserTable = () => {
    const [users, setUsers] = useState<User[]>([]);

    const [orderBy, setOrderBy] = useState<keyof User>('id');
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const fetchedUsers = await adminGetUsers();
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        loadUsers();
    }, []);


    const handleRequestSort = (property: keyof User) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const filteredAndSortedUsers = useMemo(() => {
        return users
            .filter(user => 
                Object.values(user).some(value => 
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
            .sort((a, b) => {
                if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
                if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
                return 0;
            });
    }, [users, orderBy, order, searchTerm]);

    const handleFileUpload = (userId: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            try {
                await uploadDocuments(Array.from(files), userId);
                setSnackbarMessage(`${files.length} file(s) uploaded successfully!`);
                setOpenSnackbar(true);
            } catch (error) {
                console.error('Error uploading files:', error);
                setSnackbarMessage('Error uploading files. Please try again.');
                setOpenSnackbar(true);
            }
        }
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <Box sx={{ mb: 2, p : 2 }}>
                <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="action" />
                          </InputAdornment>
                        ),
                      }}
                />
            
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {['id', 'name', 'email', 'role'].map((column) => (
                                <TableCell
                                    key={column}
                                    sortDirection={orderBy === column ? order : false}
                                    onClick={() => handleRequestSort(column as keyof User)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                    {orderBy === column ? (order === 'desc' ? ' ▼' : ' ▲') : ''}
                                </TableCell>
                            ))}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedUsers.map((user) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell>
                                    <Link
                                        to={`/admin/taxes/${user.id}`}
                                        style={{ textDecoration: 'none', marginRight: '8px' }}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            startIcon={<ReceiptLongIcon />}
                                        >
                                            Taxes
                                        </Button>
                                    </Link>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        component="label"
                                        startIcon={<UploadFileIcon />}
                                    >
                                        Upload Files
                                        <input
                                            type="file"
                                            hidden
                                            multiple
                                            onChange={handleFileUpload(user.id)}
                                        />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            </Box>
        </>
    );
}

export default AdminUserTable