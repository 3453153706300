import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FlagIcon from '@mui/icons-material/Flag';
import UnFlagIcon from '@mui/icons-material/FlagOutlined';
import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { adminExtractDocument, adminValidateDocument, deleteDocument, fetchDocumentById, openDocument } from '../../data';
import { Account, AccountType, Activity, BankExpense, CharitableDonation, ChildcareExpense, CommonPosition, Contact, ExpenseType, FieldFlag, FlagType, GiftExpense, IncomeType, InsuranceExpense, InsuranceType, isSameContact, itemType, Pension, PensionExpense, PensionType, Person, Portfolio, Position, PositionType, SalaryIncome, Security, User, UserDocument, UserRole } from '../../model';

/***
 * Data Labelling / Validation screen
 * - Flag fields that we are not sure what the right values are
 * - Mark a document as not relevant (for taxes)
 * - Add or remove items (extracted from the document)
 * - Change the type of an item from PositionType & ActivityType
 */

type UnionOfKeys<T> = T extends any ? keyof T : never;

interface FieldDefinition<T> {
    title: any;
    type: 'string' | 'number' | 'contact' | 'currency' | 'array' | 'date';
    of_type?: string;
    values?: string[]
    supported_types: (T | 'ALL')[]
    hide?: boolean
    prevalidated ?: boolean
}

const ActivityFields: Record<UnionOfKeys<InsuranceExpense | PensionExpense | SalaryIncome | ChildcareExpense | GiftExpense | CharitableDonation | BankExpense>, FieldDefinition<IncomeType | ExpenseType>> = {
    type: { title: "Type", type: "string", supported_types: ["ALL"], hide: true },

    // Common fields for all activities
    self: { title: "Self", type: "contact", values: [], supported_types: ["ALL"], prevalidated : true},
    other: { title: "Other", type: "contact", values: [], supported_types: ["ALL"], prevalidated : true },

    currency: { title: "Currency", type: "string", values: [], supported_types: ["ALL"] },
    amount: { title: "Amount", type: "currency", values: [], supported_types: ["ALL"] },

    // InsuranceExpense specific fields
    insuranceType: { title: "Insurance Type", type: "string", values: Object.values(InsuranceType), supported_types: [ExpenseType.INSURANCE_EXPENSE] },
    costShareAmount: { title: "Cost Share", type: "currency", values: [], supported_types: [ExpenseType.INSURANCE_EXPENSE] },
    policyNumber: { title: "Policy Number", type: "string", values: [], supported_types: [ExpenseType.INSURANCE_EXPENSE], prevalidated : true },

    // PensionExpense specific fields
    pensionType: { title: "Pension Type", type: "string", values: Object.values(PensionType), supported_types: [ExpenseType.PENSION_EXPENSE] },

    // SalaryIncome specific fields
    grossAmount: { title: "Gross Amount", type: "currency", values: [], supported_types: [IncomeType.SALARY_INCOME] },

    flaggedFields: { title: "Flagged fields", type: "string", values: [], supported_types: ['ALL'], hide: true }
};

const PositionFields: Record<UnionOfKeys<Account | Security | Portfolio | Pension | CommonPosition>, FieldDefinition<PositionType>> = {
    type: { title: "Type", type: "string", supported_types: ["ALL"], hide: true },

    /** BEGIN FINANCIAL POSITIONS */
    owner: { title: "Owner", type: "contact", values: [], supported_types: ["ALL"], prevalidated : true },
    issuer: { title: "Issuer", type: "contact", values: [], supported_types: ["ALL"], prevalidated : true },

    // Begin ids
    accountType: { title: "Account Type", type: "string", values: Object.values(AccountType), supported_types: [PositionType.CASH] },
    iban: { title: "IBAN", type: "string", values: [], supported_types: [PositionType.CASH], prevalidated : true },
    number: { title: "Account Number", type: "string", values: [], supported_types: [PositionType.CASH], prevalidated : true },

    name: { title: "Name", type: "string", values: [], supported_types: [PositionType.SECURITY, PositionType.COMMODITY, PositionType.CRYPTO], prevalidated : true },
    isin: { title: "ISIN", type: "string", values: [], supported_types: [PositionType.SECURITY, PositionType.COMMODITY, PositionType.CRYPTO], prevalidated : true },
    ticker: { title: "Ticker", type: "string", values: [], supported_types: [PositionType.SECURITY, PositionType.COMMODITY, PositionType.CRYPTO], prevalidated : true },

    pensionType: { title: "Pension Type", type: "string", values: Object.values(PensionType), supported_types: [PositionType.PENSION] },

    value: { title: "Value", type: "currency", values: [], supported_types: ["ALL"] },
    currency: { title: "Currency", type: "string", values: [], supported_types: ["ALL"] },
    valueDate: { title: "As of date", type: "date", values: [], supported_types: [PositionType.CASH] },

    units: { title: "Units", type: "number", values: [], supported_types: [PositionType.SECURITY, PositionType.COMMODITY, PositionType.CRYPTO] },

    incomeA: { title: "Income A", type: "currency", values: [], supported_types: [PositionType.PORTFOLIO] },
    incomeB: { title: "Income B", type: "currency", values: [], supported_types: [PositionType.PORTFOLIO] },
    positions: { title: "Positions", type: "array", values: [], supported_types: [PositionType.PORTFOLIO] },

    totalExpenses: { title: "Total Expenses", type: "currency", values: [], supported_types: [PositionType.PORTFOLIO, PositionType.CASH] },
    interestCharged: { title: "Interest Charged", type: "currency", values: [], supported_types: [PositionType.CASH] },
    interestEarned: { title: "Interest Earned", type: "currency", values: [], supported_types: [PositionType.CASH] },

    closedDate: { title: "Closed Date", type: "date", values: [], supported_types: [PositionType.CASH] },
    flaggedFields: { title: "Flagged fields", type: "string", values: [], supported_types: ['ALL'], hide: true }
}

const buildValidationMatrix = (userId : number, validatedDocuments : UserDocument[]) : Record<string, any[]> => {
    const values : Record<string, any[]> = {}
    console.log("buildin matrixs:", validatedDocuments.length, " userid:", userId)
    // Contruct a set of validated fields based on the validated documents for the same user!
    for(const val of validatedDocuments.filter(d => d.userId === userId)) {
        for(const item of [...val.positions, ...val.activities]){
            const fields = itemType(item.type) === 'position' ? PositionFields : ActivityFields;

            for(const key of Object.keys(fields)){
                if(item[key] && fields[key].prevalidated && (!item.flaggedFields || !item.flaggedFields.find(i => i.field === key))){
                    if(!values[key]){
                        values[key] = []
                    }
                    if(values[key].indexOf(item[key]) === -1){
                        values[key].push(item[key])
                    }
                }
            }
        }
    }
    console.log("values:", values)
    return values
}

const isPreValidated = (value : any, key : string, matrix : Record<string, any[]>, type : 'string' | 'number' | 'contact' | 'currency' | 'array' | 'date') : boolean => {
    if(key in matrix){
        for(const val of matrix[key]){
            if(type === 'contact'){
                if(isSameContact(value, val)){
                    return true
                }
            }
            if(val === value){
                return true
            }
        }
    }
    return false
}

const AdminDocumentView = ({ user, id, onValidated, onDeleted, validatedDocuments }: { user: User, id: number, onValidated: (document: UserDocument) => void; onDeleted: (document: UserDocument) => void; validatedDocuments : UserDocument[]}) => {
    const [document, setDocument] = useState<UserDocument | null>(null);
    const [items, setItems] = useState<(Position | Activity)[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    const [validationMatrix, setValidationMatrix] = useState<Record<string, any[]>>({})

    const isAdmin = user.role === UserRole.ADMIN

    const validatedColor = '#e6ffe6'

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const doc = await fetchDocumentById(Number(id), user); // Fetch document by ID
                setDocument(doc);
                setItems([...doc.positions, ...doc.activities])

                setValidationMatrix(buildValidationMatrix(doc.userId, validatedDocuments))
            } catch (error) {
                console.error("Error fetching document:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDocument();
    }, [id, user, validatedDocuments]);

    const handleValidate = async () => {
        try {
            await adminValidateDocument(document.id, document);
        } catch (error) {
            console.error("Error updating document:", error);
        }

        onValidated(document)
    };

    const handleExtraction = async () => {
        setLoading(true)
        const newDoc = await adminExtractDocument(document.id)
        if (newDoc) {
            setDocument(newDoc)
            setLoading(false)
        }
    }

    const changeItemType = (item: any, oldType: any, newType: any) => {
        if (itemType(oldType) !== itemType(newType)) {
            // The type changed so lets propagate some important changs
            if (itemType(oldType) === "position") {
                item['self'] = item['owner']
                item['other'] = item['issuer']
                delete item['owner']
                delete item['issuer']
            }
            if (itemType(oldType) === "activity") {
                item['owner'] = item['self']
                item['issuer'] = item['other']
                delete item['self']
                delete item['other']
            }
        }
        updateFunction(item, 'type', newType)
    }


    const handleChange = (field: string, value: any) => {
        setDocument({ ...document, [field]: value });
    };

    const updateFunction = (item: any, key: string, value: any) => {
        const index = items.indexOf(item)
        if (index !== -1) {
            item[key] = value
            const newItems = [...items]
            newItems[index] = item

            updateItems(newItems)
        }
    }

    const updateItems = (newItems: (Position | Activity)[]) => {
        setItems(newItems)
        // Update original document set
        const positions: Position[] = newItems.filter(i => itemType(i.type) === 'position').map(i => i as Position)
        const activities: Activity[] = newItems.filter(i => itemType(i.type) === 'activity').map(i => i as Activity)
        setDocument({ ...document, positions: positions, activities: activities })
    }

    const newItem = () => {
        let newItems = []
        if (items.length > 0) {
            // we copy the last item
            newItems = [...items, items[items.length - 1]]
        } else {
            const newPosition: Activity = { amount: 0, self: undefined, other: undefined, grossAmount: undefined, currency: 'CHF', type: undefined }
            newItems = [...items, newPosition]
        }
        updateItems(newItems)
    }

    const removeItem = (index: any) => {
        if (window.confirm('Are you sure you want to remove this item?')) {
            let newItems = items.filter((_, i) => i !== index);
            updateItems(newItems);
        }
    }

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await deleteDocument(document.id, user);
                onDeleted(document)
            } catch (error) {
                console.error("Cathc error", error)
            }
        }
    };

    const renderFlag = (item: Position | Activity, key: string) => {
        const isFlagged = item.flaggedFields?.find(f => f.field === key)
        return <Box sx={{ mt: -1, mb: -3, mr: 0, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
                onClick={() => {
                    const flaggedFields = item.flaggedFields || [];
                    const newFlaggedFields = isFlagged
                        ? flaggedFields.filter(f => f.field !== key)
                        : [...flaggedFields, { field: key, flag: FlagType.NOT_SURE } as FieldFlag];

                    updateFunction(item, 'flaggedFields', newFlaggedFields);
                }}
                disabled={!isAdmin}
                size="small"
                sx={{ color: isFlagged ? 'red' : 'lightgray', padding: 0 }}
            >
                {isFlagged ? <FlagIcon fontSize="inherit" /> : <UnFlagIcon fontSize="inherit" />}
            </IconButton>
        </Box>
    }

    const renderContactField = (item: Position | Activity, key: string, definition: FieldDefinition<any>, valid : boolean) => {
        const contact: Contact = item[key]
        let person: Person

        if (contact.type === "NATURAL") {
            person = contact
        }

        return <>
            <Box sx={{ width: '100%', p: 1, ml: 2, mt: 2, pb: 3.5, mr: 0, border: '1px solid #ccc', borderRadius: '3px', backgroundColor: valid ? validatedColor : 'inherit' }} key={key}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>{definition.title}</Typography>
                {item[key]?.type === 'NATURAL' ? (
                    <>
                        <Box display="flex" flexDirection="row" gap={2} sx={{ mb: 1 }}>
                            <TextField fullWidth label="First Name" value={item[key]?.firstName || ''} disabled={!isAdmin} onChange={(e) => updateFunction(item, key, { ...item[key], firstName: e.target.value })} />
                            <TextField fullWidth label="Middle Name" value={item[key]?.middleName || ''} disabled={!isAdmin} onChange={(e) => updateFunction(item, key, { ...item[key], middleName: e.target.value })} />
                            <TextField fullWidth label="Last Name" value={item[key]?.lastName || ''} disabled={!isAdmin} onChange={(e) => updateFunction(item, key, { ...item[key], lastName: e.target.value })} />
                        </Box>
                        {person.co ? <Box display="flex" flexDirection="row" gap={2} sx={{ mb: 1 }}>
                            <TextField fullWidth label="Co / First Name" value={person.co.firstName || ''} disabled={!isAdmin} onChange={(e) => updateFunction(item, key, { ...item[key], co: { ...item[key].co, firstName: e.target.value } })} />
                            <TextField fullWidth label="Co / Middle Name" value={person.co.middleName || ''} disabled={!isAdmin} onChange={(e) => updateFunction(item, key, { ...item[key], co: { ...item[key].co, middleName: e.target.value } })} />
                            <TextField fullWidth label="Co / Last Name" value={person.co.lastName || ''} disabled={!isAdmin} onChange={(e) => updateFunction(item, key, { ...item[key], co: { ...item[key].co, lastName: e.target.value } })} />
                        </Box> : ''}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: -1, mb: 0 }}>
                            {!person.co ? <Button
                                variant="text"
                                size="small"
                                onClick={() => updateFunction(item, key, { ...item[key], co: { firstName: '', middleName: '', lastName: '' } })}
                                sx={{ color: "lightgray" }}
                                disabled={!isAdmin}
                            >
                                Add co
                            </Button>
                                : <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => updateFunction(item, key, { ...item[key], co: undefined })}
                                    sx={{ color: "lightgray" }}
                                    disabled={!isAdmin}
                                >
                                    Remove co
                                </Button>
                            }
                        </Box>
                        <Box display="flex" flexDirection="row" gap={2} sx={{ mb: 1 }}>
                            <TextField
                                fullWidth
                                label="Birthday"
                                type="date"
                                value={item[key]?.birthday || ''}
                                disabled={!isAdmin}
                                onChange={(e) => updateFunction(item, key, { ...item[key], birthday: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                fullWidth
                                label="AHV Number"
                                value={item[key]?.ahvNumber || ''}
                                disabled={!isAdmin}
                                onChange={(e) => updateFunction(item, key, { ...item[key], ahvNumber: e.target.value })}
                            />
                        </Box>
                    </>
                ) : <Box display="flex" flexDirection="row" gap={2} sx={{ mb: 1 }}>
                    <TextField
                        fullWidth
                        label="Company Name"
                        value={item[key]?.companyName || ''}
                        disabled={!isAdmin}
                        onChange={(e) => updateFunction(item, key, { ...item[key], companyName: e.target.value })} />
                </Box>}
                {renderFlag(item, key)}
            </Box>
        </>
    }

    const renderField = (item: Position | Activity, key: string, definition: FieldDefinition<any>) => {
        let finalValue = item[key]
        if (definition.type === 'date') {
            finalValue = (item[key] ? item[key].split('T')[0] : '')
        }

        const valid : boolean = isPreValidated(item[key], key, validationMatrix,  definition.type)

        switch (definition.type) {
            case 'contact':
                return renderContactField(item, key, definition, valid)
            case 'currency':
                return <Grid item xs={12} sm={6} md={4} key={key}>
                    <TextField
                        key={key}
                        fullWidth
                        label={definition.title}
                        type='text'
                        disabled={!isAdmin}
                        value={(finalValue || 0).toLocaleString('de-CH')}
                        sx={{ backgroundColor: valid ? validatedColor : 'inherit' }}
                        onChange={(e) => {
                            const value = e.target.value.replace(/[^\d.]/g, '');
                            updateFunction(item, key, parseFloat(value))
                        }}
                        margin="normal"
                    />
                    {renderFlag(item, key)}
                </Grid>
            case 'string':
            case 'number':
                return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                        <TextField
                            key={key}
                            fullWidth
                            label={definition.title}
                            type={definition.type}
                            disabled={!isAdmin}
                            value={finalValue}
                            
                            onChange={(e) => {
                                updateFunction(item, key, definition.type === 'number' ? parseFloat(e.target.value) : e.target.value)
                            }}
                            select={definition.type === 'string' && definition.values.length > 0}
                            margin="normal"
                            sx={{ backgroundColor: valid ? validatedColor : 'inherit' }}
                        >
                            {definition.type === 'string' && definition.values.length > 0 && definition.values.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        {renderFlag(item, key)}
                    </Grid>
                );
            case 'date':
                return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                        <TextField
                            key={key}
                            fullWidth
                            label={definition.title}
                            type='date'
                            disabled={!isAdmin}
                            value={finalValue}
                            onChange={(e) => {
                                updateFunction(item, key, e.target.value)
                            }}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            sx={{ backgroundColor: valid ? validatedColor : 'inherit' }}
                        >
                        </TextField>
                        {renderFlag(item, key)}
                    </Grid>
                );
            default:
                return null;
        }
    }


    const renderItem = (item: Position | Activity) => {
        const fields = itemType(item.type) === 'position' ? PositionFields : ActivityFields;

        const filteredFields = Object.entries(fields)
            .reduce((acc, [key, field]) => {
                if (field.hide) {
                    return acc;
                }

                // Apply the filtering logic
                if (Array.isArray((field as any).supported_types) &&
                    !(field as any).supported_types.includes("ALL") &&
                    !(field as any).supported_types.includes(item.type)) {
                    return acc;
                }
                // If not editable only show fields with actual values
                if (!isAdmin && (item[key] === undefined || item[key] === '')) {
                    return acc;
                }
                acc.push({ key, field });
                return acc;
            }, [] as { key: string; field: Partial<FieldDefinition<any>> }[]);

        return <>
            <Grid item xs={12} sm={6} md={4} key={"type"}>
                {/* RENDER TYPE FIELD */}
                <TextField
                    key="type"
                    fullWidth
                    label="type"
                    type="string"
                    disabled={!isAdmin}
                    value={item.type}
                    onChange={(e) => {
                        changeItemType(item, item.type, e.target.value)
                    }}
                    select={true}
                    margin="normal"
                >
                    <MenuItem divider disabled={true}>
                        - Asset & Liabilities -
                    </MenuItem>
                    {Object.values(PositionType as Record<string, string>).map(type => <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>)}
                    <MenuItem divider disabled={true}>
                        - Income -
                    </MenuItem>
                    {Object.values(IncomeType as Record<string, string>).map(type => <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>)}
                    <MenuItem divider disabled={true}>
                        - Expense -
                    </MenuItem>
                    {Object.values(ExpenseType as Record<string, string>).map(type => <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>)}
                </TextField>
            </Grid>
            <Box sx={{ mt: 0, mb: 0, padding: 0, borderRadius: '4px' }}>
                <Grid container spacing={2}>
                    {filteredFields.map(({ key, field }) => (
                        <React.Fragment key={key}>
                            {renderField(item, key, field as FieldDefinition<any>)}
                        </React.Fragment>
                    ))}
                </Grid>
            </Box>
        </>
    }

    const renderData = () => {
        if (!items || items.length < 0) return null;

        return (
            <Box component="form" noValidate autoComplete="off" sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                    <TextField
                        fullWidth
                        label="Tax Year"
                        type="number"
                        value={document.taxYear || ''}
                        onChange={(e) => handleChange('taxYear', parseInt(e.target.value))}
                        disabled={!isAdmin}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Date"
                        type="date"
                        value={document.date ? document.date.split('T')[0] : ''}
                        disabled={!isAdmin}
                        onChange={(e) => handleChange('date', e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        select
                        label="Relevant"
                        value={document.notTaxRelevant ? 'false' : 'true'}
                        onChange={(e) => handleChange('notTaxRelevant', e.target.value === 'false')}
                        disabled={!isAdmin}
                        margin="normal"
                    >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </TextField>
                </Box>
                <Box sx={{ width: '100%', mb: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Tabs
                        value={selectedItemIndex}
                        onChange={(event, newValue) => setSelectedItemIndex(newValue)}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ flexGrow: 1 }}
                    >
                        {items.map((item, index) => (
                            <Tab key={index} label={`${index + 1} - ${items[index].type}`} />
                        ))}
                    </Tabs>
                    <IconButton
                        onClick={() => newItem()}
                        size="small"
                        sx={{ ml: 2 }}
                    >
                        <AddIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        onClick={() => removeItem(selectedItemIndex)}
                        size="small"
                        sx={{ ml: 1 }}
                        disabled={items.length <= 1}
                    >
                        <RemoveIcon fontSize="small" />
                    </IconButton>
                </Box>
                {items.map((item, index) => (
                    <Box key={index} hidden={selectedItemIndex !== index}>
                        {renderItem(item)}
                    </Box>
                ))}
            </Box>
        );
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
            {document ? (
                <Paper elevation={3} sx={{ p: 3, flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
                        <Box sx={{ maxWidth: '80%' }}>
                            <Typography variant="h4" fontWeight="bold">
                                {document.title}
                            </Typography>
                            <Typography variant="caption">
                                {document.filename}
                            </Typography>
                            <Typography variant="caption" sx={{ ml: 5 }}>
                                {document.date}
                            </Typography>
                            {document.notTaxRelevant && (
                                <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                                    Document not relevant for taxes
                                </Typography>
                            )}
                        </Box>
                        <Box>
                            <IconButton
                                aria-label="download original document"
                                onClick={() => openDocument(document.id, user)}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <>
                        {renderData()}
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleValidate}
                                sx={{ mt: 2 }}
                            >
                                Validate
                            </Button>
                            {!document.validated && <Button
                                variant="outlined"
                                onClick={handleExtraction}
                                sx={{ mt: 2 }}
                            >
                                Run data extraction
                            </Button>}
                        </>
                    </>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ mt: 2, backgroundColor: 'red' }}
                        onClick={handleDelete}
                    >Delete
                    </Button>
                </Paper>
            ) : (
                <Typography variant="body1">Document not found.</Typography>
            )}
        </Box>
    );
};

export default AdminDocumentView;