import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button, Typography } from "@mui/material";

export const Intro: React.FC = () => {
  const handleGoogleLogin = () => {
    // Redirect to Google OAuth route
    window.location.href = '/api/auth/google';
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      p: 3
    }}>
      <Typography variant="h2" component="h1" sx={{ color: 'primary.main', mb: 4 }}>
        You.ai
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={() => handleGoogleLogin()}
        >
          Login with Google
        </Button>
      </Box>
    </Box>
  );
};