import {
    Box,
    Card,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { User } from '../../model';
import AdminDocumentTable from './AdminDocumentTable';
import AdminUserTable from './AdminUserTable';

const Admin = ({ user }: { user: User }) => {
    const [activeTab, setActiveTab] = useState<'users' | 'documents'>('documents');

    const handleTabChange = (tab: 'users' | 'documents') => {
        setActiveTab(tab);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>Admin</Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs value={activeTab} onChange={(_, newValue) => handleTabChange(newValue)}>
                    <Tab label="Users" value="users" />
                    <Tab label="Documents" value="documents" />
                </Tabs>
            </Box>

            {activeTab === 'users' && (
                <Card elevation={3} sx={{ mb: 4, p: 0 }}>
                    <AdminUserTable />
                </Card>
            )}

            {activeTab === 'documents' && (
                <Card elevation={3} sx={{ mb: 4, p: 0 }}>
                    <AdminDocumentTable user={user} />
                </Card>
            )}
        </Box>
    );
};

export default Admin;
