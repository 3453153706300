export interface Address {
    street?: string;
    houseNumber?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    country?: string;
}

export interface Person {
    type: "NATURAL"

    firstName?: string;
    middleName?: string;
    lastName?: string;

    address?: Address

    ahvNumber?: string;
    birthday?: string;

    co ?: {firstName : string, middleName : string, lastName : string}
}

export interface Company {
    type: "LEGAL"

    companyName?: string;
    address?: Address
}

export type Contact = Person | Company


export function capitalizeWord(word: string): string {
    if (word.length === 0) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const getContactName = (contact: Contact): string => {
    if (contact?.type === "NATURAL") {
        return [contact.firstName, contact.middleName, contact.lastName].filter(Boolean).map(capitalizeWord).join(" ");
    } else if (contact?.type === "LEGAL") {
        return contact.companyName
    }
}

export const getNiceAddress = (address : Address) : string => {
    if(address){
        return [address.country, address.city, address.state, address.postalCode, address.street, address.houseNumber].filter(Boolean).join(", ")
    }
}
export enum IncomeType {
    SALARY_INCOME = 'SALARY_INCOME',
}

export enum ExpenseType {
    INSURANCE_EXPENSE = 'INSURANCE_EXPENSE',
    PENSION_EXPENSE = 'PENSION_EXPENSE',
    CHILDCARE_EXPENSE = 'CHILDCARE_EXPENSE',
    GIFT = 'GIFT',
    CHARITABLE_DONATION = 'CHARITABLE_DONATION',
    BANK_EXPENSES = 'BANK_EXPENSES'
}

export enum FlagType {
    NOT_SURE = 'NOT_SURE'
}

export interface FieldFlag {
    field : string;
    flag : FlagType
}


export interface AbstractActivity {
    type : unknown;
    self ?: Contact;
    other ?: Contact;

    currency : string;
    amount :number;

    flaggedFields ?: FieldFlag[] // list of fields flagged during the review process
}

export interface SalaryIncome extends AbstractActivity {
    type : IncomeType.SALARY_INCOME;
    grossAmount : number;
} 

export enum InsuranceType {
    HEALTH_INSURANCE = 'HEALTH_INSURANCE',
    OTHER = "OTHER"
} 

export interface InsuranceExpense extends AbstractActivity {
    type : ExpenseType.INSURANCE_EXPENSE
    insuranceType : InsuranceType // TODO: whcih others?
    costShareAmount : number; // effective cost
    policyNumber : string;
}

export enum PensionType {
    AHV_IV = '1_AHV_IV',
    BVG = '2_BVG',
    SAULE_3A = '3_SAULE_A'
}

export interface PensionExpense extends AbstractActivity {
    type : ExpenseType.PENSION_EXPENSE
    pensionType : PensionType // TODO: whcih others?
}

export interface ChildcareExpense extends AbstractActivity {
    type : ExpenseType.CHILDCARE_EXPENSE
}

export interface GiftExpense extends AbstractActivity {
    type : ExpenseType.GIFT
}

export interface CharitableDonation extends AbstractActivity {
    type : ExpenseType.CHARITABLE_DONATION
}

export interface BankExpense extends AbstractActivity {
    type : ExpenseType.BANK_EXPENSES
}

export type Activity = InsuranceExpense | PensionExpense | SalaryIncome | ChildcareExpense | GiftExpense | CharitableDonation | BankExpense

export interface AbstractPosition {
    owner ?: Contact;
    issuer ?: Contact;

    currency ?: string;
    value : number;
    valueDate ?: string;

    units ?: number;

    flaggedFields ?: FieldFlag[] // list of fields flagged during the review process

    // Do we need this fields?
    name ?: string;

    closedDate ?: string; // date when the position was closed (if closed)
}

export interface CommonPosition extends AbstractPosition {
    type : PositionType.PRIVATE_EQUITY | PositionType.REAL_ESTATE | PositionType.OTHER | PositionType.LIFE_INSURANCE;
}

export interface Pension extends AbstractPosition {
    type : PositionType.PENSION
    pensionType : PensionType;
}

export interface Portfolio extends AbstractPosition {
    type: PositionType.PORTFOLIO

    incomeA?: number;
    incomeB?: number;

    positions : Position[]

    totalExpenses ?: number;
}

export interface PublicTradedPosition extends AbstractPosition {
    type: unknown;
    isin ?: string;
    ticker ?: string;
}

export interface Commodity extends PublicTradedPosition {
    type : PositionType.COMMODITY
}

export interface Security extends PublicTradedPosition {
    type: PositionType.SECURITY
}

export interface Crypto extends PublicTradedPosition {
    type : PositionType.CRYPTO
}

export interface Account extends AbstractPosition {
    type: PositionType.CASH;
    accountType?: AccountType; // Only for Position

    iban?: string;
    number?: string;

    interestCharged?: number;
    interestEarned?: number;

    totalExpenses ?: number;
}

export type Position = Account | Security | Portfolio | CommonPosition | Pension

export enum UserRole {
    ADMIN = "ADMIN",
    USER = "USER"
}

export enum AccountType {
    CURRENT = 'CURRENT',
    SAVING = 'SAVING',
    DEPOT = 'DEPOT',
    RETIREMENT = 'RETIREMENT', // Should be in potiions type
    CREDIT = 'CREDIT',
    OTHER = 'OTHER'
}

export enum PositionType {
    PORTFOLIO = 'PORTFOLIO',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    PRIVATE_EQUITY = 'PRIVATE_EQUITY',
    REAL_ESTATE = 'REAL_ESTATE',
    SECURITY = 'SECURITY',
    COMMODITY = 'COMMODITY',
    LIFE_INSURANCE = 'LIFE_INSURANCE',
    PENSION = 'PENSION',
    OTHER = 'OTHER'
}

export const itemType = (type: PositionType | ExpenseType | IncomeType): 'position' | 'activity' => {
    // @ts-ignore
    if (Object.values(PositionType).includes(type)) {
        return 'position';
    }
    // @ts-ignore
    if (Object.values(IncomeType).includes(type) || Object.values(ExpenseType).includes(type)) {
        return 'activity';
    }
}

export interface User {
    id: number;
    name : string;
    email : string;
    role : UserRole;
}

export interface UserDocument {
    id: number;
    userId : number;

    date ?: string;
    title ?: string;
    taxYear ?: number;
    filename: string;

    positions : Position[];
    activities : Activity[];

    schema ?: string;

    state?: 'PROCESSING' | 'PROCESSED'

    validated : boolean;
    deleted : boolean;
    notTaxRelevant : boolean;
}

export interface DocumentValidation {
    date ?: string;
    title ?: string;
    taxYear ?: number;

    positions : Position[];
    activities : Activity[];

    notTaxRelevant ?: boolean;
}

/*** BEGIN TAX DOMAIN */

export interface TaxRow {
    _id?: number;
    _title?: string;
    _contact?: Contact;
    _style: "SECTION" | "TITLE" | "NORMAL1" | "NORMAL2" | "HIDDEN"
    _value?: {federal: number, state: number };
    _hidden_value ?: any;
    bookId?: string;
}

export interface Book {
    type : "POSITIONS" | "ROWS"
    positionsType ?: "ASSETS" | "LIABILITIES" // filter
    rows ?: TaxRow[]
    positions ?: Position[];
}

export interface TaxDeclaration {
    year: number;

    household: TaxHousehold;
    persons: TaxPerson[];
    summary: TaxSummary;

    books: { [key: string]: Book };
}

export interface TaxSummary {
    income: Total;
    wealth: Total;

    taxRateIncome: number;
    taxRateWealth: number;
    taxRateBund: number;

    taxRateKanton: number;
    taxRateGemeinde: number;
}

export enum MartialStatus {
    SINGLE = "SINGLE",
    MARRIED = "MARRIED",
    SEPARATED = "SEPARATED",
    WIDOWED = "WIDOWED"
}

export interface TaxProfile {
    contact : Person;
    maritalStatus?: MartialStatus;
    numberOfChildren?: number;
    childrenAges?: number[];

    //childcareCosts?: number; --> removed 27.09.
    pensionBenefitToPartner?: number;
    alimonyToPartner?: number;
    alimonyToChildren?: number;

    mainEmployer?: Company;
    secondaryEmployer?: Company;
    selfEmployer?: Company;

    bikeToWork?: boolean;
    carToWork?: boolean;
    subsidizedMeals?: boolean;
    shiftWork?: boolean;
    officePensum?: number;
    abonementCharges?: number;
    effectiveOtherExpenses?: number;
    trainingAndEducationExpenses?: number;
    motorisedKmPerYearToWork?: number;
    motorisedToWorkType?: "car" | "motorbike"
    secondaryEmploymentExpenses?: number;
}

export interface TaxPerson  {
    contact : Person;
    
    // all this info comes from PersonTaxProfile
    maritalStatus?: MartialStatus;
    numberOfChildren?: number;
    childrenAges?: number[];

    childcareCosts?: number;
    pensionBenefitToPartner?: number;
    alimonyToPartner?: number;
    alimonyToChildren?: number;

    employers : Company[];
    mainEmployer?: Company;
    secondaryEmployer?: Company;
    selfEmployer?: Company;

    bikeToWork?: boolean;
    carToWork?: boolean;
    subsidizedMeals?: boolean;
    shiftWork?: boolean;
    officePensum?: number;
    abonementCharges?: number;
    effectiveOtherExpenses?: number;
    trainingAndEducationExpenses?: number;
    motorisedKmPerYearToWork?: number;
    motorisedToWorkType?: "car" | "motorbike"
    secondaryEmploymentExpenses?: number;
    
    // Begin calculated values
    mainEmploymentIncome?: number;
    secondaryEmploymentIncome?: number;
    thirdPillarContributions?: number
}

export interface TaxHousehold {
    // Begin calculated values
    totalIncome: number;
    securityIncome: number;
    totalAssetValue: number;
    totalDebtValue: number;

    // Copied from Person[0]
    gemeinde?: string | undefined;
    kanton?: string | undefined;
    adress?: string;
    maritalStatus?: MartialStatus;
    numberOfChildren?: number;
    childrenAges?: number[];
    alimonyToPartner?: number;
    hasUnderAgeChildren?: boolean;
    alimonyToChildren?: number;
    pensionBenefitToPartner?: number;

    // Schuld sinzen
    debtInterest: number;
    investmentEarnings: number;

    // Versicherungsprämien
    healthInsurancePremiums?: number
    accidentInsurancePremiums?: number
    lifeAndPensionInsurancePremium?: number
    interestFromCapital?: number
    premiumReduction?: number

    trainingAndEducationExpenses?: number;

    totalThirdPillarContributions?: number

    // Other deductibles
    costsForManagementOfMovablePrivateAssets?: number
    totalManagedAssets?: number

    // Beiträge an AHV, IV oder 2 saule
    additionalSecondPillarContributions?: number

    // Behinderungsbedinte Kosten
    disabilityRelatedCosts?: number

    // Childcare cost
    childcareCosts?: number

    // Other Abzüge
    charitableDonations?: number
}

export interface Total {
    state: number
    federal: number
    isApplicable?: boolean
    missingAttributes?: (keyof any)[]
}

export const newTotal = (total?: number | { state: number, federal: number }): Total => {
    if (typeof total === 'number') {
        return { state: total, federal: total, isApplicable: true, missingAttributes: [] }
    } else {
        return { state: total.state, federal: total.federal, isApplicable: true, missingAttributes: [] }
    }
}

export const sumTotals = (...totals: Array<{ state: number, federal: number }>): { state: number, federal: number } => {
    return totals.reduce((acc, curr) => ({
        state: acc.state + curr.state,
        federal: acc.federal + curr.federal
    }), { state: 0, federal: 0 });
}

export const subTotals = (...totals: Array<{ state: number, federal: number }>): { state: number, federal: number } => {
    return totals.reduce((acc, curr, index) => ({
        state: index === 0 ? curr.state : acc.state - curr.state,
        federal: index === 0 ? curr.federal : acc.federal - curr.federal
    }), { state: 0, federal: 0 });
}


export const minimumTotals = (...totals: Array<{ state: number, federal: number }>): { state: number, federal: number } => {
    return totals.reduce((min, curr) => ({
        state: Math.min(min.state, curr.state),
        federal: Math.min(min.federal, curr.federal)
    }), { state: Infinity, federal: Infinity });
}

export const maximumTotals = (...totals: Array<{ state: number, federal: number }>): { state: number, federal: number } => {
    return totals.reduce((min, curr) => ({
        state: Math.max(min.state, curr.state),
        federal: Math.max(min.federal, curr.federal)
    }), { state: 0, federal: 0 });
}

const isStringSimilar = (str1: string, str2: string): boolean => {
    const threshold = 0.8;
    const length = Math.max(str1.length, str2.length);
    const distance = levenshteinDistance(str1, str2);
    return (length - distance) / length > threshold;
}

export const isSameContact = (c1: Contact, c2: Contact): boolean => {
    if (!c1 || !c2 || c1.type !== c2.type) {
        return false
    }
    if (c1.type === "LEGAL") {
        return isStringSimilar(getContactName(c1).toLowerCase(), getContactName(c2).toLowerCase())
    }
    if (isStringSimilar(getContactName(c1).toLowerCase(), getContactName(c2).toLowerCase())) {
        return true
    }
    if (c1.type === "NATURAL" && c2.type === "NATURAL") {
        // check if ahv is the same or roughly the same
        if (!!c1.ahvNumber && !!c2.ahvNumber && c1.ahvNumber.replace(/\./g, '') === c2.ahvNumber.replace(/\./g, '')) {
            return true
        }
        if (!!c1.birthday && c1.birthday === c2.birthday) { // IMPORTANT: this will fail for people with the exact same birthday --> is this an issue at all?
            return true
        }
    }
    return false
}

const levenshteinDistance = (str1: string, str2: string): number => {
    const m = str1.length;
    const n = str2.length;
    const dp: number[][] = Array(m + 1).fill(null).map(() => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) dp[i][0] = i;
    for (let j = 0; j <= n; j++) dp[0][j] = j;

    for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = Math.min(
                    dp[i - 1][j] + 1,
                    dp[i][j - 1] + 1,
                    dp[i - 1][j - 1] + 1
                );
            }
        }
    }

    return dp[m][n];
}

export const isSamePosition = (s1: Position, s2: Position): boolean => {
    const compareIds = (id1: string, id2: string): boolean => {
        if (id1 === undefined || id2 === undefined) {
            return false
        }
        return id1.replace(/[^a-zA-Z0-9]/g, '') === id2.replace(/[^a-zA-Z0-9]/g, '')
    }
    
    if (s1.type === PositionType.CASH && s2.type === PositionType.CASH) {
        if (compareIds(s1.iban, s2.iban) || compareIds(s1.number, s2.number)) {
            return true
        }
    }
    if (s1.type === PositionType.PORTFOLIO && s2.type === PositionType.CASH) {
        return !!s1.positions?.find(a => a.type === PositionType.CASH && (compareIds(a.iban, s2.iban) || compareIds(a.number, s2.number)))
    }
    if ([PositionType.PRIVATE_EQUITY, PositionType.COMMODITY, PositionType.LIFE_INSURANCE, PositionType.PORTFOLIO].includes(s1.type) &&
        [PositionType.PRIVATE_EQUITY, PositionType.COMMODITY, PositionType.LIFE_INSURANCE, PositionType.PORTFOLIO].includes(s2.type)) {
            // One document per owner & issuer
        return isSameContact(s1.owner, s2.owner) && isSameContact(s1.issuer, s2.issuer)
    }
    return false
}

export const isSameActivity = (s1: Activity, s2: Activity): boolean => {
    if (s1.type === IncomeType.SALARY_INCOME && s2.type === IncomeType.SALARY_INCOME) {
        // dedup at activity level
        return isSameContact(s1.self, s2.self) && isSameContact(s1.other, s2.other) && s1.amount === s2.amount
    }
    if (s1.type === ExpenseType.PENSION_EXPENSE && s2.type === ExpenseType.PENSION_EXPENSE) {
        // dedup at activity level possible
        return (s1.pensionType === s2.pensionType && isSameContact(s1.self, s2.self) && isSameContact(s1.other, s2.other))
    }

    if (s1.type === ExpenseType.CHILDCARE_EXPENSE && s2.type === s1.type) {
        // dedup at activity level
        return isSameContact(s1.self, s2.self) && isSameContact(s1.other, s2.other) && s1.amount === s2.amount
    }
    return false
}