import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, Card, Container, Typography, useTheme } from '@mui/material';
import { User } from '../model';

export const Home = ({ user }: { user: User }) => {
  const theme = useTheme();
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    // Redirect to the auth page
    window.location.href = '/api/auth/logout';
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Card elevation={3} sx={{ mb: 4, p: 3 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Welcome
            </Typography>
            <Button
              size="small"
              startIcon={<LogoutIcon />}
              onClick={handleLogout}
            >
            </Button>
          </Box>
          <Typography variant="body1" paragraph>
            We're excited to have you as one of the first users of our AI-powered tax automation software. This solution is designed to simplify and enhance how you manage your personal financial data, growing in capability and functionality over time.
          </Typography>
          <Typography variant="body1" paragraph>
            As an early user, you're part of something truly special. Just as AI has transformed industries like healthcare and customer service, we believe tax automation is the next frontier. Your feedback will play a crucial role in shaping the future of this tool.
          </Typography>
          <Typography variant="body1" paragraph>
            This is just the beginning. We're committed to continuously improving the product, making it an even more powerful tool that gives you greater control over your finances.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
            Thank you for joining us on this exciting journey!
          </Typography>
        </Card>
      </Box>
    </Container>
  );
};