import {
  Box,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTaxDeclaration, updateTaxProfile } from '../../data';
import { Book, TaxDeclaration, TaxPerson, TaxProfile, User } from '../../model';
import ArticleIcon from '@mui/icons-material/Article';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { openTaxes } from '../../data';
import { formatCurrency, positionReference } from '../../format';
import { getContactName, Position, PositionType, TaxRow } from '../../model';
import { ProfileEdit } from '../ProfileEdit';
import { TaxesOverview } from './TaxesOverview';

const renderBook = (book : Book, onBookOpen ?: (bookId : string) => void) => {
  if(book.type === "POSITIONS"){
    return renderPositions(book.positions, book.positionsType || "ASSETS")
  } else {
    return renderRows(book.rows, onBookOpen)
  }
}

const renderRows = (rows: TaxRow[], onBookOpen?: (bookId: string) => void) => {
  const variantMap: Record<string, 'h6' | 'subtitle1'> = {
    "SECTION": "h6",
    "TITLE": "subtitle1",
    "NORMAL1": "subtitle1",
    "NORMAL2": "subtitle1",
    "BOOK_REFERENCE": "subtitle1"
  };

  const fontWeight: Record<string, 'bold' | 'normal'> = {
    "SECTION": "bold",
    "TITLE": "normal",
    "NORMAL1": "normal",
    "NORMAL2": "normal",
    "BOOK_REFERENCE": "normal"
  }

  const title = (position: TaxRow) => {
    if (position._contact) {
      return getContactName(position._contact);
    }
    return position._title;
  }

  return rows
  .filter(r => r._style !== "HIDDEN")
  .map((position, index) => (
    <Box key={index} sx={{ p: 0, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant={variantMap[position._style]} fontWeight={fontWeight[position._style]} sx={{ flex: 2 }}>
        {title(position)}
        {position.bookId ? (
          <IconButton
            size="small"
            onClick={() => onBookOpen && onBookOpen(position.bookId)}
          >
            <ArticleIcon fontSize="small" />
          </IconButton>
        ) : ''}
        <Typography variant="caption" sx={{ ml: 1, fontSize: '0.6em', verticalAlign: 'sub' }}>
          {position._id}
        </Typography>
      </Typography>
      
      {position._style === "NORMAL1" && (position._value.state !== 0) && <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }, justifyContent: 'flex-end', flex: 1 }}>
          <Typography variant="body1" fontWeight="bold" sx={{ textAlign: 'right', mb: { xs: 1, sm: 0 } }}>
            {formatCurrency(Math.abs(position._value.state))}
          </Typography>
        </Box>}

      {position._style === "NORMAL2" && (position._value.state !== 0 || position._value.state !== 0)  && (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }, justifyContent: 'flex-end', flex: 1 }}>
          <Typography variant="body1" fontWeight="bold" sx={{ flex: { sm: 0.5 }, textAlign: 'right', mb: { xs: 1, sm: 0 } }}>
            {formatCurrency(position._value.state)}
          </Typography>
          <Typography variant="body1" fontWeight="bold" sx={{ flex: { sm: 0.5 }, textAlign: 'right' }}>
            {formatCurrency(position._value.federal)}
          </Typography>
        </Box>
      )}
    </Box>
  ));
};

const renderPositions = (positions: Position[], filter : "ASSETS" | "LIABILITIES") => {
  if (filter === "LIABILITIES") {
    const liabilites = positions.filter(p => p.value < 0)

    return <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Währung</TableCell>
            <TableCell>Privatschulden</TableCell>
            <TableCell align="right">Steuerwert am 31.12.</TableCell>
            <TableCell align="right">Schuldzinsen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {liabilites
            .map((pos: Position, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{pos.currency}</TableCell>
                  <TableCell>{[getContactName(pos.issuer), positionReference(pos)].filter(Boolean).join(", ")}</TableCell>
                  <TableCell align="right">{formatCurrency(pos.value)}</TableCell>
                  <TableCell align="right">{(pos.type === PositionType.CASH && pos.interestCharged) ? formatCurrency(pos.interestCharged) : ''}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell align="right">{formatCurrency(liabilites.filter(p => p.value < 0).reduce((sum, pos) => sum + (pos.value || 0), 0))}</TableCell>
            <TableCell align="right">{formatCurrency(liabilites.filter(p => p.value < 0).reduce((sum, pos) => sum + (pos.type === PositionType.CASH && pos.interestCharged ? pos.interestCharged : 0), 0))}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </>
  }

  const incomeA = (position: Position): number => {
    if (position.type === PositionType.PORTFOLIO) {
      return position.incomeA
    }
    return undefined
  }

  const incomeB = (position: Position): number => {
    if (position.type === PositionType.PORTFOLIO) {
      return position.incomeB
    }
    if (position.type === PositionType.CASH) {
      return position.interestEarned
    }
    return undefined
  }

  // ASSETS
  const assets = positions.filter(p => p.value >= 0)
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Währung</TableCell>
            <TableCell>Bezeichnung der Vermögenswerte</TableCell>
            <TableCell align="right">Steuerwert am 31.12.</TableCell>
            <TableCell align="right">Bruttoertrag A</TableCell>
            <TableCell align="right">Bruttoertrag B</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets
            .map((pos: Position, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{pos.currency}</TableCell>
                  <TableCell>{[getContactName(pos.issuer), positionReference(pos)].filter(Boolean).join(", ")}</TableCell>
                  <TableCell align="right">{formatCurrency(pos.value)}</TableCell>
                  <TableCell align="right">{incomeA(pos) ? formatCurrency(incomeA(pos)) : ''}</TableCell>
                  <TableCell align="right">{incomeB(pos) ? formatCurrency(incomeB(pos)) : ''}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell align="right">{formatCurrency(assets.reduce((sum, pos) => sum + (pos.value || 0), 0))}</TableCell>
            <TableCell align="right">{formatCurrency(assets.reduce((sum, pos) => sum + (incomeA(pos) ? incomeA(pos) : 0), 0))}</TableCell>
            <TableCell align="right">{formatCurrency(assets.reduce((sum, pos) => sum + (incomeB(pos) ? incomeB(pos) : 0), 0))}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </>
  );
};


export const Taxes = ({ user }: { user: User}) => {
  const [selectedYear, setSelectedYear] = useState(2022);
  const [declaration, setDeclaration] = useState<TaxDeclaration | undefined>();
  const [selectedPerson, setSelectedPerson] = useState<TaxPerson>(undefined);
  const [selectedBookId, setSelectedBookId] = useState<string | null>(null);

  const params = useParams();
  const years = [2020, 2021, 2022, 2023, 2024]; // Add more years as needed

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const handleOpenProfileEdit = (profile: TaxPerson) => {
    setSelectedPerson(profile)
  };

  const handleCloseProfileEdit = () => {
    setSelectedPerson(undefined)
  };

  const handleBookOpen = (bookId: string) => {
    setSelectedBookId(bookId);
  };

  const handleCloseBookDialog = () => {
    setSelectedBookId(null);
  };

  const handleSaveProfile = async (profile: TaxProfile) => {
    const newDeclaration = await updateTaxProfile(declaration.year, profile, params?.otherUserId)
    setSelectedPerson(undefined)
    setDeclaration(newDeclaration)
  }


  React.useEffect(() => {
    fetchTaxDeclaration(selectedYear, params?.otherUserId).then((declaration: TaxDeclaration) => {
      setDeclaration(declaration);
    });
  }, [selectedYear, params]);

  return (
    <Box sx={{ p: 2, bgcolor: 'background.default' }}>
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>Taxes
        {params?.otherUserId && (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
            <SupervisorAccountIcon color="error" />
            <Typography variant="caption" color="error" sx={{ ml: 0.5 }}>
              Admin
            </Typography>
          </Box>
        )}
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5" color="text.secondary">Fiscal Year</Typography>
        <TextField
          select
          value={selectedYear}
          onChange={handleYearChange}
          variant="outlined"
          size="small"
          sx={{
            minWidth: 120,
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'primary.main' },
              '&:hover fieldset': { borderColor: 'primary.main' },
              '&.Mui-focused fieldset': { borderColor: 'primary.main' },
            },
            '& .MuiSelect-select': {
              paddingY: 1,
              color: 'primary.main',
              fontWeight: 'bold',
            },
          }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {declaration && <><Grid container spacing={2}>
        <Grid item xs={12}>
          <TaxesOverview summary={declaration.summary} />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ bgcolor: 'background.paper' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>Personal Information</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {declaration.persons.map((person, index) => (
                  <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="body1">
                      {getContactName(person.contact)}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenProfileEdit(person)}
                    >
                      Edit
                    </Button>
                  </Box>
                ))}
                <Typography variant="body1"><strong>Address:</strong> {declaration.household.adress}</Typography>
                <Typography variant="body1"><strong>Gemeinde:</strong> {declaration.household.gemeinde}</Typography>
                <Typography variant="body1"><strong>Kanton:</strong> {declaration.household.kanton}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ bgcolor: 'background.paper', p: 2 }}>
            {renderBook(declaration.books['main'], handleBookOpen)}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={() => openTaxes(declaration.year, params?.otherUserId)}
              >
                Download Tax Declaration
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
        <Dialog
          open={!!selectedPerson}
          onClose={handleCloseProfileEdit}
          fullWidth
          maxWidth="md"
          fullScreen={window.innerWidth <= 600}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseProfileEdit}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
              zIndex: theme.zIndex.modal + 1,
            })}
          >
            <CloseIcon />
          </IconButton>
          {<DialogContent dividers>
            {selectedPerson && (
              <ProfileEdit
                profile={selectedPerson}
                employers={selectedPerson.employers}
                onSave={handleSaveProfile}
                onCancel={handleCloseProfileEdit}
              />
            )}
          </DialogContent>}
        </Dialog>

        <Dialog
          open={!!selectedBookId}
          onClose={handleCloseBookDialog}
          fullWidth
          maxWidth="md"
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseBookDialog}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
              zIndex: theme.zIndex.modal + 1,
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            {selectedBookId && renderBook(declaration.books[selectedBookId])}
          </DialogContent>
        </Dialog>
      </>}
    </Box>
  );
};