import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { useState } from 'react';
import { User } from '../model';

const thinkingAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const ThinkingText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.light} 50%, ${theme.palette.background.paper} 100%)`,
  backgroundSize: '200% 100%',
  animation: `${thinkingAnimation} 2s linear infinite`,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 4,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

export const Assistant = ({ user }: { user: User }) => {
  const [step, setStep] = useState(0);
  const [thinking, setThinking] = useState(false);
  const [thinkingText, setThinkingText] = useState('');

  const simulateThinking = (duration: number, texts: string[]) => {
    setThinking(true);
    let i = 0;
    const interval = setInterval(() => {
      setThinkingText(texts[i % texts.length]);
      i++;
    }, 500);
    setTimeout(() => {
      clearInterval(interval);
      setThinking(false);
      setStep(prevStep => prevStep + 1);
    }, duration);
  };

  const handleStart = () => {
    simulateThinking(3000, ['Preparing tax filing process...', 'Initializing...', 'Getting ready...']);
  };

  const handleUpload = () => {
    simulateThinking(4500, ['Uploading documents...', 'Reading files...', 'Analyzing data...']);
  };

  const handleContinue = () => {
    simulateThinking(3000, ['Preparing questions...', 'Finalizing analysis...', 'Almost there...']);
  };

  const handleSubmitAnswers = () => {
    simulateThinking(3000, ['Calculating taxes...', 'Generating report...', 'Finalizing...']);
  };

  return (
    <Box sx={{ p: 2, bgcolor: 'background.default', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 2 }}>
        Tax Assistant
      </Typography>
      <StyledCard>
        {step === 0 && (
          <>
            <Typography variant="h6" gutterBottom>Welcome to the Tax Filing Assistant</Typography>
            <Typography variant="body1" paragraph>
              I can help you file your taxes for 2023. Would you like to start the process?
            </Typography>
            <Button variant="contained" onClick={handleStart}>Start Tax Filing</Button>
          </>
        )}

        {step === 1 && (
          <>
            <Typography variant="h6" gutterBottom>Document Upload</Typography>
            <Typography variant="body1" paragraph>
              To proceed with your tax filing, we need your relevant documents. Please upload your folder containing all necessary files.
            </Typography>
            <Button variant="contained" startIcon={<UploadFileIcon />} onClick={handleUpload}>
              Upload Documents
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Typography variant="h6" gutterBottom>Document Analysis Complete</Typography>
            <Typography variant="body1" paragraph>
              I've finished analyzing your documents. Here are some insights:
            </Typography>
            <ul>
              <li>Your total salary for 2023 was 120,000 CHF</li>
              <li>Your salary deposits in the bank match your stated income</li>
            </ul>
            <Button variant="contained" onClick={handleContinue}>Continue</Button>
          </>
        )}

        {step === 3 && (
          <>
            <Typography variant="h6" gutterBottom>Additional Information</Typography>
            <Typography variant="body1" paragraph>
              To complete your tax filing, please answer the following questions:
            </Typography>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">Is it correct that you are married?</FormLabel>
              <RadioGroup row>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={<Checkbox />}
              label="Do you bike to work? (If you bike frequently, a total of 700 CHF can be reduced from taxes)"
            />
            <TextField
              fullWidth
              label="If you use public transport to go to work, how much is your yearly abo?"
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <Button variant="contained" sx={{ mt: 2 }} onClick={handleSubmitAnswers}>
              Submit Answers
            </Button>
          </>
        )}

        {step === 4 && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <ReceiptLongIcon sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h5" fontWeight="bold" color="primary.main">
                Tax Calculation Overview
              </Typography>
            </Box>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: 'primary.main' }}>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Item</TableCell>
                    <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>Amount (CHF)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Income</TableCell>
                    <TableCell align="right">120,000</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Deductions</TableCell>
                    <TableCell align="right">15,700</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Taxable Income</TableCell>
                    <TableCell align="right">104,300</TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: 'secondary.light' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>Estimated Tax</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>22,946</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Based on the information provided, your estimated tax for 2023 is <strong>22,946 CHF</strong>. This includes deductions for your public transport expenses and biking to work.
            </Typography>
            <Button variant="contained" color="primary">
              Download Full Report
            </Button>
          </>
        )}

        {thinking && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <CircularProgress size={24} sx={{ mr: 2 }} />
            <ThinkingText variant="body1">{thinkingText}</ThinkingText>
          </Box>
        )}
      </StyledCard>
    </Box>
  );
};