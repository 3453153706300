import { Contact, getContactName, Position, PositionType } from "./model"

export const formatCurrency = (amount : number, currency ?: string) => {
    if(currency){
        return amount?.toLocaleString('de-CH', { style: 'currency', currency: currency, maximumFractionDigits: 0  })
    }
    return amount?.toLocaleString('de-CH', { maximumFractionDigits: 0  })
}

export const contactName = (contact : Contact) => {
    if(contact){
        return getContactName(contact)
    }
}

export const positionReference = (position : Position) : string => {
    if(position.type === PositionType.CASH){
      return position.iban || position.number || position.name
    } else if(position.type === PositionType.SECURITY){
      return position.isin ||  position.name
    }  
    return position.name
  }