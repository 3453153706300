import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    Button,
    Card,
    Collapse,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { TaxSummary } from '../../model';
import { formatCurrency } from '../../format';

export const TaxesOverview = ({ summary }: { summary : TaxSummary }) => {
    const [showExtendedView, setShowExtendedView] = useState(false);

    const getFederalIncome = (): number => {
        return summary.income.federal || 0
    }

    const getStateIncome = (): number => {
        return summary.income.state || 0
    }

    const getWealth = (): number => {
        return summary.wealth.state || 0
    }

    const getSimpleTax = (): number => {
        return getStateIncome() * summary.taxRateIncome + getWealth() * summary.taxRateWealth;
    }

    const getBundTax = (): number => {
        return getFederalIncome() * summary.taxRateBund;
    }

    const getKantonTax = (): number => {
        return getSimpleTax() * summary.taxRateKanton;
    }

    const getGemeindeTax = (): number => {
        return getSimpleTax() * summary.taxRateGemeinde;
    }

    const getTotalTax = (): number => {
        return getKantonTax() + getGemeindeTax() + getBundTax();
    }

    const taxesBreakdown = () => {
        return (
            <Box>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>Tax Calculation Breakdown</Typography>
                
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" gutterBottom sx={{ color: 'primary.main' }}>Federal Tax</Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                        Federal tax is calculated based on your federal taxable income, using progressive tax rates.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Federal Tax</Typography>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">{formatCurrency(getBundTax())}</Typography>
                            <Typography variant="caption" color="text.secondary">{`${formatCurrency(getFederalIncome())} × ${(summary.taxRateBund * 100).toFixed(2)}%`}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" gutterBottom sx={{ color: 'primary.main' }}>Cantonal and Municipal Tax</Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                        Cantonal and municipal taxes are calculated based on your cantonal taxable income and wealth. 
                        The rates vary by canton and municipality.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Income Tax</Typography>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">{formatCurrency(getStateIncome() * summary.taxRateIncome)}</Typography>
                            <Typography variant="caption" color="text.secondary">{`${formatCurrency(getStateIncome())} × ${(summary.taxRateIncome * 100).toFixed(2)}%`}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Wealth Tax</Typography>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">{formatCurrency(getWealth() * summary.taxRateWealth)}</Typography>
                            <Typography variant="caption" color="text.secondary">{`${formatCurrency(getWealth())} × ${(summary.taxRateWealth * 100).toFixed(2)}%`}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Cantonal Tax</Typography>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">{formatCurrency(getKantonTax())}</Typography>
                            <Typography variant="caption" color="text.secondary">{`${formatCurrency(getSimpleTax())} × ${(summary.taxRateKanton * 100).toFixed(2)}%`}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">Municipal Tax</Typography>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">{formatCurrency(getGemeindeTax())}</Typography>
                            <Typography variant="caption" color="text.secondary">{`${formatCurrency(getSimpleTax())} × ${(summary.taxRateGemeinde * 100).toFixed(2)}%`}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, fontWeight: 'bold' }}>
                    <Typography variant="body2">Total Tax</Typography>
                    <Box sx={{ textAlign: 'right' }}>
                        <Typography variant="body2">{formatCurrency(getTotalTax())}</Typography>
                    </Box>
                </Box>
                <Typography variant="caption" color="text.secondary">
                    (Federal Tax + Cantonal Tax + Municipal Tax)
                </Typography>
            </Box>
        )
    }

    return (
        <Card sx={{ bgcolor: 'background.paper' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Overview</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ alignSelf: 'flex-end' }}>Taxable Income (State)</Typography>
                        <Typography variant="h6" sx={{ alignSelf: 'flex-end' }}>{formatCurrency(getStateIncome())}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" color="text.secondary"  sx={{ alignSelf: 'flex-end' }}>Taxable Income (Federal)</Typography>
                        <Typography variant="h6" sx={{ alignSelf: 'flex-end' }}>{formatCurrency(getFederalIncome())}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" color="text.secondary"  sx={{ alignSelf: 'flex-end' }}>Taxable Wealth</Typography>
                        <Typography variant="h6" sx={{ alignSelf: 'flex-end' }}>{formatCurrency(getWealth())}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ p: 2, bgcolor: 'primary.light', borderRadius: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" color="primary.contrastText"  sx={{ alignSelf: 'flex-end' }}>Total Tax Due</Typography>
                        <Typography variant="h6" color="primary.contrastText" sx={{ alignSelf: 'flex-end' }}>{formatCurrency(getTotalTax())}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    size="small"
                    endIcon={showExtendedView ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setShowExtendedView(!showExtendedView)}
                    sx={{ textTransform: 'none' }}
                >
                    {showExtendedView ? 'Hide' : 'Show'} Calculation Details
                </Button>
            </Box>

            <Collapse in={showExtendedView} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 2, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                    {taxesBreakdown()}
                </Box>
            </Collapse>
        </Card>
    );
};