import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';

import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getContactName, User } from '../model';
import { fetchContacts } from '../data';

export const Contacts = ({ user }: { user: User }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [contacts, setContacts] = useState([]);
  const [editingContact, setEditingContact] = useState<null | (typeof contacts)[0]>(null);
  const [contactType, setContactType] = useState<'NATURAL' | 'LEGAL'>('NATURAL');

  useEffect(() => {
    fetchContacts().then(contacts => setContacts(contacts))
  }, []);

  const filteredContacts = contacts?.filter(contact =>
    getContactName(contact)?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    contact.type === contactType
  );

  return (
    <Box sx={{ p: 2, bgcolor: 'background.default' }}>
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>Contacts</Typography>

      <Tabs value={contactType} onChange={(_, newValue) => setContactType(newValue)} sx={{ mb: 2 }}>
        <Tab label="Person" value="NATURAL" />
        <Tab label="Company" value="LEGAL" />
      </Tabs>

      <TextField
        fullWidth
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
        }}
        sx={{
          mb: 2,
          bgcolor: 'background.paper',
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: '#E5E7EB' },
            '&:hover fieldset': { borderColor: '#E5E7EB' },
            '&.Mui-focused fieldset': { borderColor: 'primary.main' },
          },
        }}
      />

      <Grid container spacing={2}>
        {filteredContacts?.map((contact, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ p: 2, height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: 'primary.main', color: 'white', mr: 2 }}>
                  {contact.type === "NATURAL" ? <PersonIcon /> : <BusinessIcon />}
                </Avatar>
                <Box>
                  <Tooltip title={getContactName(contact)}>
                    <Typography variant="subtitle1" fontWeight="medium">
                      {getContactName(contact)}
                    </Typography>
                  </Tooltip>
                  <Typography variant="body2" color="text.secondary">
                    {contact.type === "NATURAL" ? "Person" : "Company"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                
              {contact.type === "NATURAL" && contact.birthday && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      {contact.birthday}
                    </Typography>
                  </Box>)}
                {contact.type === "NATURAL" && contact.ahvNumber && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      {contact.ahvNumber}
                    </Typography>
                  </Box>)}
                {contact.address?.street && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      {`${contact.address.street}${contact.address.houseNumber ? ` ${contact.address.houseNumber}` : ''}`}
                    </Typography>
                  </Box>
                )}
                {contact.address?.city && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      {`${contact.address.city}${contact.address.postalCode ? `, ${contact.address.postalCode}` : ''}`}
                    </Typography>
                  </Box>
                )}
                {contact.address?.country && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">{contact.address.country}</Typography>
                  </Box>
                )}
              </Box>
              {/*<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={() => setEditingContact(contact)}>Edit</Button>
                <Button>Mark as Duplicate</Button>
              </Box>*/}
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={!!editingContact} onClose={() => setEditingContact(null)}>
        <DialogTitle>Edit Contact</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            fullWidth
            value={getContactName(editingContact) || ''}
            onChange={(e) => setEditingContact(prev => prev ? { ...prev, fullName: e.target.value } : null)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingContact(null)}>Cancel</Button>
          <Button>Save</Button>
        </DialogActions>
      </Dialog>

      {/*<Dialog open={!!duplicateContact} onClose={() => setDuplicateContact(null)}>
        <DialogTitle>Mark as Duplicate</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Select the contact that this is a duplicate of:
          </Typography>
          <Select
            fullWidth
            value={selectedDuplicateOf || ''}
            onChange={(e) => setSelectedDuplicateOf(e.target.value)}
          >
            {contacts.filter(c => c.fullName !== duplicateContact?.fullName).map((contact) => (
              <MenuItem key={contact.fullName} value={contact.fullName}>{contact.fullName}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setDuplicateContact(null);
            setSelectedDuplicateOf('');
          }}>Cancel</Button>
          <Button onClick={handleConfirmDuplicate} disabled={!selectedDuplicateOf}>Confirm</Button>
        </DialogActions>
      </Dialog >*/}
    </Box>
  );
};
