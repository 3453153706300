import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FlagIcon from '@mui/icons-material/Flag';
import UnFlagIcon from '@mui/icons-material/FlagOutlined';
import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { adminExtractDocument, adminValidateDocument, deleteDocument, fetchDocumentById, openDocument } from '../data';
import { Account, AccountType, Activity, BankExpense, CharitableDonation, ChildcareExpense, CommonPosition, Contact, ExpenseType, FieldFlag, FlagType, GiftExpense, IncomeType, InsuranceExpense, InsuranceType, Pension, PensionExpense, PensionType, Person, Portfolio, Position, PositionType, SalaryIncome, Security, User, UserDocument, UserRole } from '../model';

const DocumentView = ({ user, id, onDeleted }: { user: User, id: number, onDeleted: (document: UserDocument) => void; }) => {
    const [document, setDocument] = useState<UserDocument | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const doc = await fetchDocumentById(Number(id), user); // Fetch document by ID
                setDocument(doc);
            } catch (error) {
                console.error("Error fetching document:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDocument();
    }, [id, user]);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await deleteDocument(document.id, user);
                onDeleted(document)
            } catch (error) {
                console.error("Cathc error", error)
            }
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
            {document ? (
                <Paper elevation={3} sx={{ p: 3, flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
                        <Box sx={{ maxWidth: '80%' }}>
                            <Typography variant="h4" fontWeight="bold">
                                {document.title}
                            </Typography>
                            <Typography variant="caption">
                                {document.filename}
                            </Typography>
                            <Typography variant="caption" sx={{ ml: 5 }}>
                                {document.date}
                            </Typography>
                            {document.notTaxRelevant && (
                                <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                                    Document not relevant for taxes
                                </Typography>
                            )}
                        </Box>
                        <Box>
                            <IconButton
                                aria-label="download original document"
                                onClick={() => openDocument(document.id, user)}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, width: '100%', height: 'calc(100vh - 200px)' }}>
                            <iframe
                                src={`/api/document/${document.id}/download`}
                                width="100%"
                                height="100%"
                                style={{ border: 'none' }}
                                title={document.title}
                            />
                        </Box>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ mt: 2, backgroundColor: 'red' }}
                        onClick={handleDelete}
                    >Delete
                    </Button>
                </Paper>
            ) : (
                <Typography variant="body1">Document not found.</Typography>
            )}
        </Box>
    );
};

export default DocumentView;