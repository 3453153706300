import CssBaseline from '@mui/material/CssBaseline';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(
  <>
    <CssBaseline />
    <App />
  </>
);