import { Contact, DocumentValidation, Position, TaxDeclaration, TaxProfile, User, UserDocument, UserRole } from "./model";

export function checkedFetch(url: string, params?: any) {
  return fetch(url, params).then(response => {
    if (!response.ok && window.location.pathname !== '/') {
      window.location.href = '/';
      throw new Error('Unauthorized');
    }
    return response;
  });
}

export const fetchUser = async (): Promise<User> => {
  const response = await checkedFetch("/api/user");
  const userData = await response.json();

  return userData as User;
}

export const fetchPositions = async (year : number): Promise<Position[]> => {
  const response = await checkedFetch("/api/position/"+year);
  return await response.json();
}


export const fetchContacts = async (): Promise<Contact[]> => {
  const response = await checkedFetch("/api/contact");
  return await response.json();
}

export const fetchDocuments = async (): Promise<UserDocument[]> => {
  const response = await checkedFetch("/api/document");
  return await response.json();
}

export const fetchDocumentById = async (documentId: number, user : User): Promise<UserDocument> => {
  let url = `/api/document/${documentId}`
  if(user.role === UserRole.ADMIN){
    url = `api/admin/document/${documentId}`
  }
  const response = await fetch(url);
  return await response.json();
}

export const deleteDocument = async(documentId : number, user : User): Promise<UserDocument> => {
  let url = `/api/document/${documentId}`
  if(user.role === UserRole.ADMIN){
    url = `api/admin/document/${documentId}`
  }
  const response = await fetch(url, {
    method: 'DELETE',
  });
  return await response.json();
}


export const openDocument = (documentId: number, user : User) => {
  let url = `/api/document/${documentId}/download`
  if(user.role === UserRole.ADMIN){
    url = `/api/admin/document/${documentId}/download`
  }
  window.open(url, '_blank');
}

export const openTaxes = (taxYear: number, otherUserId ?: any) => {
  let url = `/api/taxes/${taxYear}/download`
  if(otherUserId){
    url = `/api/admin/${otherUserId}/taxes/${taxYear}/download`
  }
  window.open(url, '_blank');
}

export const fetchTaxDeclaration = async (year: number, otherUserId ?: any): Promise<TaxDeclaration> => {
  try {
    let url = `/api/taxes/${year}`
    if (otherUserId) {
      // Impersonation mode
      url = `/api/admin/${otherUserId}/taxes/${year}`
    }
    const response = await checkedFetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch tax declaration');
    }
    return await response.json() as TaxDeclaration;
  } catch (error) {
    console.error('Error fetching tax declaration:', error);
  }
};

export const uploadDocuments = async (files: File[], otherUserId ?: any): Promise<void> => {
  try {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    let url = '/api/document/upload'
    if(otherUserId){
      // Impersonation mode
      url = `/api/admin/document/upload/${otherUserId}/`
    }

    const response = await checkedFetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload documents');
    }

    const result = await response.json();
    console.log('Upload successful:', result);
  } catch (error) {
    console.error('Error uploading documents:', error);
    throw error;
  }
};

export const updateTaxProfile = async (year: number, profile: TaxProfile, otherUserId: string): Promise<TaxDeclaration> => {
  try {
    let url =`/api/taxes/${year}/profile`

    if (otherUserId) {
      url = `/api/admin/${otherUserId}/taxes/${year}/profile`
    }

    const response = await checkedFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profile),
    });

    if (!response.ok) {
      throw new Error('Failed to update tax profile');
    }

    return await response.json() as TaxDeclaration;
  } catch (error) {
    console.error('Error updating tax profile:', error);
    throw error;
  }
};

export const adminGetUsers = async (): Promise<User[]> => {
  const response = await checkedFetch("/api/admin/user");
  return await response.json();
}

export const adminGetDocuments = async (): Promise<UserDocument[]> => {
  const response = await checkedFetch("/api/admin/document");
  return await response.json();
}

export const adminValidateDocument = async (documentId: number, doc: UserDocument): Promise<UserDocument> => {
  const update: DocumentValidation = {
    date: doc.date,
    title: doc.title,
    taxYear: doc.taxYear,
    positions: doc.positions,
    activities: doc.activities,
    notTaxRelevant : doc.notTaxRelevant
  }

  try {
    const response = await checkedFetch(`/api/admin/document/${documentId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
    });

    if (!response.ok) {
      throw new Error('Failed to update document');
    }

    return await response.json() as UserDocument;
  } catch (error) {
    console.error('Error updating document:', error);
    throw error;
  }
};

export const adminExtractDocument = async (documentId: number): Promise<UserDocument> => {
  try {
    const response = await checkedFetch(`/api/admin/document/extract/${documentId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Failed to update document');
    }

    return await response.json() as UserDocument;
  } catch (error) {
    console.error('Error updating document:', error);
    throw error;
  }
};